<template>
  <div class="mt-5">
    <div class="text-center my-2" v-if="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-container v-else>
      <h1>
        <b-button pill class="order-back-button" variant="dark" size="sm" :to="{ name: 'User/Orders' }">
          <b-icon-chevron-left></b-icon-chevron-left>
        </b-button>
        {{ order.live ? $t("order.title-user", { shopName: order.live.shop.name }) : $t("order.title") }}
      </h1>

      <b-row align-v="stretch" class="mt-5">
        <b-col cols="4" v-if="order.live">
          <b-card class="h-100">
            <h4>{{ $t("order.contact") }}</h4>
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t("order.email") }}:</strong>&nbsp;{{ order.live.shop.email }}
              </li>
              <li>
                <strong>{{ $t("order.phone") }}:</strong>&nbsp;{{ order.live.shop.phone }}
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card class="h-100">
            <h4>{{ $t("order.order-details") }}</h4>
            <ul class="list-unstyled">
              <li v-if="order.date">
                <strong>{{ $t("order.date") }}:</strong>&nbsp;{{ formatDate(order.date) }}
              </li>
              <li v-if="order.live && order.live.expectedStartTime">
                <strong>{{ $t("order.live-date") }}:</strong>&nbsp;{{ formatDate(order.live.expectedStartTime) }}
              </li>
              <li>
                <strong>{{ $t("order.status") }}:</strong>&nbsp;{{ $t(`orders.status.${order.status}`) }}
              </li>
              <li>
                <strong>{{ $t("order.payment-method") }}:</strong>&nbsp;
                {{ $t(`stream.cart.payment-method.${order.paymentMethod}`) }}
              </li>
              <li v-if="discount">
                <strong v-if="discount.discountInfos === 'shared-10'">{{ $t("order.discount") }}:</strong>&nbsp;{{
                  formattedDiscount
                }}
              </li>
              <li>
                <strong>{{ $t("order.total") }}:</strong>&nbsp;
                <span v-if="order.totalWithDiscount && order.totalWithDiscount.amount !== order.total.amount">
                  <span class="strike mr-2">{{ formatPrice(order.total) }}</span>
                  <b>{{ formatPrice(order.totalWithDiscount) }}</b>
                </span>
                <span v-else>{{ formatPrice(order.total) }}</span>
              </li>
              <li v-if="order.paymentMethod === 'bank_wire' && order.live.shop.iban">
                <br />
                <strong>{{ $t("order.bank-wire") }}:</strong><br />
                <ul class="list-unstyled">
                  <li>
                    {{ order.live.shop.iban }}
                  </li>
                  <li>
                    {{ order.live.shop.name }}
                  </li>
                  <li>
                    {{ order.live.shop.address.address1 }}
                  </li>
                  <li v-if="order.live.shop.address.address2">
                    {{ order.live.shop.address.address2 }}
                  </li>
                  <li>{{ order.live.shop.address.postalCode }}, {{ order.live.shop.address.city }}</li>
                  <li>
                    {{ order.live.shop.address.country }}
                  </li>
                </ul>
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card class="h-100">
            <h4>{{ $t("order.delivery-address") }}</h4>
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t("order.delivery-method") }}:</strong>&nbsp;{{
                  $t(`stream.cart.delivery-method.${order.deliveryMethod}`)
                }}
                <br />
              </li>
              <li>
                {{ deliveryAddress.address1 }}
              </li>
              <li v-if="deliveryAddress.address2">
                {{ deliveryAddress.address2 }}
              </li>
              <li>
                {{ deliveryAddress.postalCode }},
                {{ deliveryAddress.city }}
              </li>
              <li>
                {{ deliveryAddress.country }}
              </li>
              <li v-if="shipping">
                <br />
                <strong>{{ $t("order.shipping-cost") }}:</strong>&nbsp;{{ formatPrice(shipping.totalPrice) }}
              </li>
              <li v-if="order.comment">
                <br />
                <strong>{{ $t("order.user-comment") }}:</strong>
                <p>{{ order.comment }}</p>
                <p v-if="order.hasVoucher">{{ $t("stream.cart.has-voucher") }}</p>
                <br />
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
      <b-table responsive :items="products" :fields="columns" class="mt-5">
        <template #cell(images)="row">
          <b-img v-if="row.item.product.photoUrl" :src="row.item.product.photoUrl" width="50" fluid />
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import api from "@/utils/api";
import helper from "@/utils/helper";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Order",
  data() {
    return {
      order: null,
      isLoading: false,
      columns: [
        {
          key: "images",
          label: this.$t("order.items.columns.photo"),
          thStyle: "width: 50px;",
        },
        {
          key: "product",
          label: this.$t("order.items.columns.name"),
          sortable: true,
          class: "align-middle",
          formatter: value => {
            let name = value.name;

            if (this.order.live) {
              const product = this.order.live.products.find(liveProduct => liveProduct.product.id === value.id);
              name += ` #${product.order}`;
            }

            return name;
          },
        },
        {
          key: "productVariant",
          label: "",
          sortable: false,
          class: "align-middle",
          formatter: value => {
            return value ? value.attributes.size : "";
          },
        },
        {
          key: "unitPrice",
          label: this.$t("order.items.columns.unit-price"),
          sortable: true,
          class: "align-middle",
          formatter: value => this.formatPrice(value),
        },
        {
          key: "quantity",
          label: this.$t("order.items.columns.quantity"),
        },
        {
          key: "totalPrice",
          label: this.$t("order.items.columns.total-price"),
          sortable: true,
          class: "align-middle",
          formatter: value => this.formatPrice(value),
        },
      ],
    };
  },
  computed: {
    products() {
      return this.order.items.filter(item => item.type === "product");
    },
    shipping() {
      return this.order.items ? this.order.items.find(item => item.type === "shipping") : null;
    },
    discount() {
      return this.order.items ? this.order.items.find(item => item.type === "discount") : null;
    },
    formattedDiscount() {
      return this.discount.discountType === "percentage"
        ? `${this.discount.discount} %`
        : `-${this.formatPrice({ amount: this.discount.discount, currency: this.order.total.currency })}`;
    },
    deliveryAddress() {
      if (!this.order) {
        return;
      }

      if (this.order.deliveryMethod !== "shop_pick_up") {
        return this.order.deliveryAddress;
      }

      if (this.order.deliveryShop) {
        return this.order.deliveryShop;
      }

      return this.order.live.shop.address;
    },
  },
  created() {
    this.isLoading = true;

    api
      .userGetOrder(this.$route.params.id)
      .then(response => (this.order = response.data))
      .catch(e => {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      })
      .then(() => (this.isLoading = false));
  },
  methods: {
    formatDate(date) {
      return helper.formatDate(date);
    },
    formatPrice(price) {
      return helper.formatPrice(price);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-back-button {
  padding: 4px 8px 5px 7px;
  position: relative;
  top: -5px;
}
</style>
