<template>
  <div>
    <h1>This is the audience page</h1>
    <button @click="start">Join</button>&nbsp;<button @click="stop">
      Leave
    </button>
    <div ref="player"></div>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Audience",
  data() {
    return {
      client: null,
      uid: null,
    };
  },
  methods: {
    async start() {
      try {
        this.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
        await this.client.setClientRole("audience");

        this.uid = await this.client.join(
          "ede0c91cb1674b4990923505c6525b17",
          "stream-2",
          "006ede0c91cb1674b4990923505c6525b17IAC6LmBMDDikhj\\/6PBWzXjZSgSe4CFhGnnXK6iGhEgtr9K8qimO379yDEAChRBsCMAMcYAEAAQDw6Rpg",
          1
        );

        this.client.on("user-published", async (user, mediaType) => {
          // Subscribe to a remote user.
          await this.client.subscribe(user, mediaType);
          console.log("subscribe success");

          // If the subscribed track is video.
          if (mediaType === "video") {
            // Get `RemoteVideoTrack` in the `user` object.
            const remoteVideoTrack = user.videoTrack;
            // // Dynamically create a container in the form of a DIV element for playing the remote video track.
            const playerContainer = document.createElement("div");
            // Specify the ID of the DIV container. You can use the `uid` of the remote user.
            playerContainer.id = user.uid.toString();
            playerContainer.style.width = "640px";
            playerContainer.style.height = "480px";
            this.$refs.player.append(playerContainer);

            // Play the remote video track.
            // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
            remoteVideoTrack.play(playerContainer);

            // Or just pass the ID of the DIV container.
            // remoteVideoTrack.play(playerContainer.id);
          }

          // If the subscribed track is audio.
          if (mediaType === "audio") {
            // Get `RemoteAudioTrack` in the `user` object.
            const remoteAudioTrack = user.audioTrack;
            // Play the audio track. No need to pass any DOM element.
            remoteAudioTrack.play();
          }
        });

        this.client.on("user-unpublished", user => {
          const playerContainer = document.getElementById(user.uid);
          playerContainer && playerContainer.remove();
        });
      } catch (e) {
        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        await this.stop();
        alert("erreur");
      }
    },
    async stop() {
      // Traverse all remote users.
      this.client.remoteUsers.forEach(user => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });

      // Leave the channel.
      await this.client.leave();
    },
  },
};
</script>
