import axios from "axios";
import AUTHENTICATION from "@/utils/constants/authentication";
import QueryString from "qs";

const existingToken = localStorage.getItem(AUTHENTICATION.TOKEN_KEY);
const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_MESSAGING_API_URL}/`,
  headers: {
    common: {
      Authorization: existingToken ? `Bearer ${existingToken}` : "",
    },
  },
  paramsSerializer: params => QueryString.stringify(params),
});

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem(AUTHENTICATION.TOKEN_KEY);
  if (token) {
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };
  }
  return config;
});

export default {
  sendMessage(liveId, text) {
    return apiClient.post(`${liveId}/message`, { text });
  },
  like(liveId) {
    return apiClient.post(`${liveId}/like`);
  },
  startProduct(liveId, productId, startTime) {
    return apiClient.post(`${liveId}/startProduct/${productId}`, { startTime });
  },
  stopProduct(liveId, productId, endTime) {
    return apiClient.post(`${liveId}/stopProduct/${productId}`, { endTime });
  },
  random(liveId) {
    return apiClient.post(`${liveId}/random`);
  },
  productAddedToCart(liveId, product) {
    return apiClient.post(`${liveId}/productAddedToCart`, product);
  },
};
