var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-black",class:{ 'pt-5': _vm.loggedIn, 'pt-4': !_vm.loggedIn }},[(_vm.isLoading)?_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1):_c('div',{staticClass:"bg-black pb-5 home-container w-75 mx-auto"},[_c('div',{staticClass:"text-center"},[(!_vm.loggedIn)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-login",modifiers:{"modal-login":true}}],staticClass:"bg-orange text-center text-white",attrs:{"variant":"dark","pill":""}},[_vm._v(" "+_vm._s(_vm.$t("common.connect-or-register"))+" ")]):_vm._e()],1),_vm._l((_vm.sortedLives),function(shops,type){return _c('div',{key:("" + type)},[(shops.length > 0)?[_c('h3',{staticClass:"ml-2 text-white py-3"},[_vm._v(_vm._s(_vm.$t(("user.live." + type))))]),_c('div',{staticClass:"scrool-H"},_vm._l((shops),function(shop){return _c('router-link',{key:(type + "-" + (shop.id)),class:{ 'pr-2': type !== 'last' },attrs:{"to":{
              name: 'UserStream',
              params: { id: _vm.getLiveForType(shop, type).id },
            }}},[_c('div',{class:{
                item: type === 'last' && !_vm.maxWidth576,
                'ml-2': type === 'last' && !_vm.maxWidth576,
                'item-top': type !== 'last' || _vm.maxWidth576,
                //'mx-2': type === 'current',
              }},[_c('div',{staticClass:"top-card bg-dark rounded-top"},[_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.getMonth(_vm.getLiveForType(shop, type).expectedStartTime)))]),_c('div',{staticClass:"right"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"clock-fill","aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(_vm.formatTime(_vm.getLiveForType(shop, type).expectedStartTime)))])],1)]),(shop.logoUrl || (shop.shop && shop.shop.logoUrl) || _vm.getLiveForType(shop, type).logoUrl)?_c('img',{staticClass:"live-img rounded rounded-top",attrs:{"src":(_vm.apiUrl + "/" + (_vm.getLiveForType(shop, type).logoUrl
                      ? _vm.getLiveForType(shop, type).logoUrl
                      : shop.shop && shop.shop.logoUrl
                      ? shop.shop.logoUrl
                      : shop.logoUrl)),"alt":"Shop logo"}}):_vm._e(),_c('div',{staticClass:"bottom-card"}),_c('p',{staticClass:"live-title"},[_vm._v(" "+_vm._s(type === "last" ? _vm.limitChars(shop.shop ? shop.shop.name : shop.name, 11) : shop.shop ? shop.shop.name : shop.name)+" "),(type !== 'last')?[_c('br'),_vm._v(_vm._s(_vm.getLiveForType(shop, type).title))]:_vm._e()],2)])])}),1)]:_vm._e()],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }