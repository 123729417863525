<template>
  <form v-if="address" ref="addressForm" @submit.stop.prevent="handleSubmit">
    <b-form-group
      :label="$t('user.address.form.address1')"
      label-for="address-address1-input"
      :invalid-feedback="$t('user.address.validation.address1')"
      :state="address.address1 !== null ? address.address1.length > 0 : null"
    >
      <b-form-input
        id="address-address1-input"
        v-model="address.address1"
        :state="address.address1 !== null ? address.address1.length > 0 : null"
        required
      />
    </b-form-group>
    <b-form-group :label="$t('user.address.form.address2')" label-for="address-address2-input">
      <b-form-input id="address-address2-input" v-model="address.address2" />
    </b-form-group>
    <b-form-group
      :label="$t('user.address.form.postalCode')"
      label-for="address-postalCode-input"
      :invalid-feedback="$t('user.address.validation.postalCode')"
      :state="address.postalCode !== null ? address.postalCode.length > 0 : null"
    >
      <b-form-input
        id="address-postalCode-input"
        v-model="address.postalCode"
        :state="address.postalCode !== null ? address.postalCode.length > 0 : null"
        required
      />
    </b-form-group>
    <b-form-group
      :label="$t('user.address.form.city')"
      label-for="address-city-input"
      :invalid-feedback="$t('user.address.validation.city')"
      :state="address.city !== null ? address.city.length > 0 : null"
    >
      <b-form-input
        id="address-city-input"
        v-model="address.city"
        :state="address.city !== null ? address.city.length > 0 : null"
        required
      />
    </b-form-group>
    <b-form-group
      :label="$t('user.address.form.country')"
      label-for="address-country-input"
      :invalid-feedback="$t('user.address.validation.country')"
      :state="address.country !== null ? address.country.length > 0 : null"
    >
      <b-form-select
        id="address-country-input"
        v-model="address.country"
        :options="countries"
        :state="address.country !== null ? address.country.length > 0 : null"
        required
      ></b-form-select>
    </b-form-group>
  </form>
</template>

<script>
import api from "@/utils/api";
import Bugsnag from "@bugsnag/js";

export default {
  name: "AddressForm",
  props: {
    address: {
      type: Object,
      default() {
        return {
          address1: null,
          address2: null,
          postalCode: null,
          city: null,
          country: "BE",
        };
      },
    },
  },
  data() {
    return {
      countries: [
        { value: "AT", text: this.$t("user.address.form.countries.AT") },
        { value: "BE", text: this.$t("user.address.form.countries.BE") },
        { value: "BG", text: this.$t("user.address.form.countries.BG") },
        { value: "HR", text: this.$t("user.address.form.countries.HR") },
        { value: "CH", text: this.$t("user.address.form.countries.CH") },
        { value: "CY", text: this.$t("user.address.form.countries.CY") },
        { value: "CZ", text: this.$t("user.address.form.countries.CZ") },
        { value: "DK", text: this.$t("user.address.form.countries.DK") },
        { value: "EE", text: this.$t("user.address.form.countries.EE") },
        { value: "FI", text: this.$t("user.address.form.countries.FI") },
        { value: "FR", text: this.$t("user.address.form.countries.FR") },
        { value: "DE", text: this.$t("user.address.form.countries.DE") },
        { value: "GR", text: this.$t("user.address.form.countries.GR") },
        { value: "HU", text: this.$t("user.address.form.countries.HU") },
        { value: "IE", text: this.$t("user.address.form.countries.IE") },
        { value: "IT", text: this.$t("user.address.form.countries.IT") },
        { value: "LV", text: this.$t("user.address.form.countries.LV") },
        { value: "LT", text: this.$t("user.address.form.countries.LT") },
        { value: "LU", text: this.$t("user.address.form.countries.LU") },
        { value: "MT", text: this.$t("user.address.form.countries.MT") },
        { value: "NL", text: this.$t("user.address.form.countries.NL") },
        { value: "PL", text: this.$t("user.address.form.countries.PL") },
        { value: "PT", text: this.$t("user.address.form.countries.PT") },
        { value: "RO", text: this.$t("user.address.form.countries.RO") },
        { value: "SK", text: this.$t("user.address.form.countries.SK") },
        { value: "SI", text: this.$t("user.address.form.countries.SI") },
        { value: "ES", text: this.$t("user.address.form.countries.ES") },
        { value: "SE", text: this.$t("user.address.form.countries.SE") },
        { value: "US", text: this.$t("user.address.form.countries.US") },
      ],
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.addressForm.checkValidity();

      if (!valid) {
        if (this.address.address1 === null) {
          this.address.address1 = "";
        }

        if (this.address.postalCode === null) {
          this.address.postalCode = "";
        }

        if (this.address.city === null) {
          this.address.city = "";
        }
      }

      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      let promise;
      if (this.address.id) {
        promise = api.updateAddress(this.address);
      } else {
        promise = api.createAddress(this.address);
      }

      return promise
        .then(response => {
          this.$emit("address-submitted", response.data);
        })
        .catch(error => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        });
    },
  },
};
</script>

<style scoped></style>
