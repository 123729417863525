<template>
  <div class="h-w-100">
    <b-navbar toggleable="lg" type="dark" variant="grey" :class="{ stream: currentShop !== null }">
      <b-navbar-brand :to="{ name: isSeller ? 'MyShop' : 'UserHome' }">
        <b-icon-chevron-left class="mr-2" v-if="$route.name === 'UserStream'"></b-icon-chevron-left>
        <template v-if="currentShop">{{ currentShop.name }}</template>
        <template v-else>
          <img src="/img/logov2_white.png" class="header-logo" alt="Logo Flixby" />
        </template>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <!--<b-navbar-nav>-->
        <!--  <b-nav-item exact-active-class="active" :to="{ name: 'MyShop/Products' }">-->
        <!--    {{ $t("seller.menu.products") }}-->
        <!--  </b-nav-item>-->
        <!--  <b-nav-item exact-active-class="active" :to="{ name: 'MyShop/Lives' }">-->
        <!--    {{ $t("seller.menu.lives") }}-->
        <!--  </b-nav-item>-->
        <!--</b-navbar-nav>-->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <locale-switcher />

          <b-nav-item-dropdown v-if="loggedIn" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em v-html="userInfo.name"></em>
            </template>
            <b-dropdown-item :to="{ name: 'User/Orders' }">{{ $t("user.menu.my-orders") }}</b-dropdown-item>
            <b-dropdown-item href="/logout">{{ $t("common.logout") }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <template v-else>
            <b-nav-item exact-active-class="active" v-b-modal.modal-login>
              {{ $t("common.login") }}
            </b-nav-item>
            <b-nav-item>
              |
            </b-nav-item>
            <b-nav-item exact-active-class="active" :href="proUrl">
              {{ $t("common.pro") }}
            </b-nav-item>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container
      id="user-container"
      :class="{ stream: currentShop !== null, 'lives-list': onlivesListView }"
      class="p-0"
      fluid
    >
      <router-view />
    </b-container>
    <b-modal
      id="modal-login"
      body-bg-variant="dark"
      body-text-variant="light"
      size="sm"
      hide-header
      hide-footer
      no-stacking
    >
      <login :is-modal="true" :reload-on-login="true"></login>
    </b-modal>
    <b-modal
      id="modal-register"
      body-bg-variant="dark"
      body-text-variant="light"
      size="sm"
      hide-header
      hide-footer
      no-stacking
    >
      <register :is-modal="true" :reload-on-login="true"></register>
    </b-modal>
    <b-modal
      id="modal-forgotten-password"
      body-bg-variant="dark"
      body-text-variant="light"
      size="sm"
      hide-header
      hide-footer
      no-stacking
    >
      <forgotten-password-email
        v-on:reset-password-email-sent="$bvModal.msgBoxOk($t('login.forgotten-password.email-sent'))"
      ></forgotten-password-email>
    </b-modal>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { mapGetters, mapState } from "vuex";
import Login from "@/components/Login";
import Register from "@/components/Register";
import ForgottenPasswordEmail from "@/components/ForgottenPasswordEmail";

export default {
  name: "User",
  components: { LocaleSwitcher, Login, Register, ForgottenPasswordEmail },
  computed: {
    ...mapState(["currentShop", "onlivesListView"]),
    ...mapGetters("authentication", ["loggedIn", "userInfo", "isSeller"]),
    proUrl() {
      return `https://${process.env.VUE_APP_PRO_DOMAIN}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#user-container {
  height: calc(100% - 56px);
}

#user-container.stream {
  height: 100% !important;
}

#user-container.lives-list {
  background-color: black;
}
</style>
