<template>
  <div class="bg-black" :class="{ 'pt-5': loggedIn, 'pt-4': !loggedIn }">
    <div class="text-center my-2" v-if="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div class="bg-black pb-5 home-container w-75 mx-auto" v-else>
      <div class="text-center">
        <b-button v-if="!loggedIn" class="bg-orange text-center text-white" variant="dark" v-b-modal.modal-login pill>
          {{ $t("common.connect-or-register") }}
        </b-button>
      </div>
      <div v-for="(shops, type) in sortedLives" :key="`${type}`">
        <template v-if="shops.length > 0">
          <h3 class="ml-2 text-white py-3">{{ $t(`user.live.${type}`) }}</h3>
          <div class="scrool-H">
            <router-link
              :to="{
                name: 'UserStream',
                params: { id: getLiveForType(shop, type).id },
              }"
              v-for="shop in shops"
              :key="`${type}-${shop.id}`"
              :class="{ 'pr-2': type !== 'last' }"
            >
              <div
                :class="{
                  item: type === 'last' && !maxWidth576,
                  'ml-2': type === 'last' && !maxWidth576,
                  'item-top': type !== 'last' || maxWidth576,
                  //'mx-2': type === 'current',
                }"
              >
                <!-- Top card -->
                <div class="top-card bg-dark rounded-top">
                  <!-- @todo parse date time for timezone -->
                  <span class="left">{{ getMonth(getLiveForType(shop, type).expectedStartTime) }}</span>
                  <div class="right">
                    <b-icon class="mr-1" icon="clock-fill" aria-hidden="true"></b-icon>
                    <span>{{ formatTime(getLiveForType(shop, type).expectedStartTime) }}</span>
                  </div>
                </div>
                <!-- Image card -->
                <!-- <div class=""> -->
                <img
                  v-if="shop.logoUrl || (shop.shop && shop.shop.logoUrl) || getLiveForType(shop, type).logoUrl"
                  class="live-img rounded rounded-top"
                  :src="
                    `${apiUrl}/${
                      getLiveForType(shop, type).logoUrl
                        ? getLiveForType(shop, type).logoUrl
                        : shop.shop && shop.shop.logoUrl
                        ? shop.shop.logoUrl
                        : shop.logoUrl
                    }`
                  "
                  alt="Shop logo"
                />
                <!-- </div> -->

                <!-- Bottom card -->
                <div class="bottom-card"></div>
                <!-- Card Title -->
                <p class="live-title">
                  {{
                    type === "last"
                      ? limitChars(shop.shop ? shop.shop.name : shop.name, 11)
                      : shop.shop
                      ? shop.shop.name
                      : shop.name
                  }}
                  <template v-if="type !== 'last'"><br />{{ getLiveForType(shop, type).title }}</template>
                </p>
              </div>
              <!-- </div> -->
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";
import helper from "@/utils/helper";
import Bugsnag from "@bugsnag/js";
import { mapGetters } from "vuex";

export default {
  name: "UserHome",
  data() {
    return {
      shops: [],
      total: null,
      isLoading: false,
      maxWidth576: this.isMaxWidth576(),
    };
  },
  computed: {
    ...mapGetters("authentication", ["loggedIn"]),
    currentLives() {
      return this.shops
        .filter(shop => shop.currentLive)
        .sort(
          (shopA, shopB) =>
            helper.getDate(shopB.currentLive.expectedStartTime).getTime() -
            helper.getDate(shopA.currentLive.expectedStartTime).getTime()
        );
    },
    nextLives() {
      const lives = [];
      this.shops
        .filter(shop => shop.nextLive)
        .forEach(shop =>
          shop.nextLives.forEach(live => {
            live.shop = shop;
            lives.push(live);
          })
        );

      return lives.sort(
        (liveA, liveB) =>
          helper.getDate(liveA.expectedStartTime).getTime() - helper.getDate(liveB.expectedStartTime).getTime()
      );
    },
    lastLives() {
      const lives = [];
      this.shops
        .filter(shop => shop.lastLive)
        .forEach(shop =>
          shop.previousLives.forEach(live => {
            live.shop = shop;
            lives.push(live);
          })
        );

      return lives.sort(
        (liveA, liveB) =>
          helper.getDate(liveB.expectedStartTime).getTime() - helper.getDate(liveA.expectedStartTime).getTime()
      );
    },
    sortedLives() {
      return {
        current: this.currentLives,
        next: this.nextLives,
        last: this.lastLives,
      };
    },
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL;
    },
  },
  created() {
    this.isLoading = true;
    this.loadShops();
  },
  mounted() {
    this.$store.commit("setOnLivesListView", true);
  },
  destroyed() {
    this.$store.commit("setOnLivesListView", false);
  },
  methods: {
    isMaxWidth576() {
      return window.matchMedia("(max-width: 576px)").matches;
    },
    formatTime(date) {
      return helper.formatTime(date);
    },
    limitChars(word, limit) {
      return helper.limitChars(word, limit);
    },
    getLiveForType(shop, type) {
      switch (type) {
        case "current":
          return shop.currentLive;
        case "next":
          return shop;
        case "last":
          return shop;
        default:
          throw new Error("Unhandled live type");
      }
    },
    getMonth(data) {
      // shop.lastLive.expectedStartTime
      let day = data.substr(8, 1) === "0" ? data.substr(9, 1) : data.substr(8, 2);
      let month = data.substr(5, 2);
      // console.log(month)

      let monthName = this.$t(`month.${month}`);
      return `${day} ${monthName}`;
    },
    loadShops(page = 1, limit = 10) {
      this.isLoading = true;

      return api
        .getHomeShops(page, limit)
        .then(response => {
          const payload = response.data;
          this.total = payload.total;
          this.shops = payload.data;

          console.log(payload);
        })
        .catch(e => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        })
        .then(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.shop {
  text-decoration: none;
  color: black;
}

// consts
.bg-black {
  background-color: black;
}

// Card A la une
.item-top {
  // width: 100%;
  height: 300px;
  text-align: center;
  color: white;
  border-radius: 10px;
  position: relative;
  // margin: 0 5%;
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.item-top .top-card {
  height: 35px;
  width: 100%;
  background-color: black;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.item-top .top-card .left {
  display: flex;
  margin-left: 10px;
  font-weight: bold;
}

.item-top .top-card .right {
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.item-top .live-img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  // position: absolute;
  // bottom: 25%;
}
.item-top .live-title {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  font-weight: bold;
  font-size: 20px;
}

.item-top .bottom-card {
  position: absolute;
  bottom: 0;
  height: 120px;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0));
  // opacity: 20%;
  display: flex;
}

.item-top .bottom-card .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 55%;
}

.item-top .bottom-card .left h1 {
  font-weight: bold;
}

.item-top .bottom-card .left span {
  line-height: 1;
  font-weight: bold;
  font-size: 15px;
}

.item-top .bottom-card .right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 45%;
}

.item-top .bottom-card .right .play-btn {
  height: 80px;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.455);
  border-radius: 50%;
  border: solid white 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .item-top .live-title {
//   position: absolute;
//   top: 320px;
//   z-index: 10;
//   width: 100%;
//   font-weight: bold;
//   font-size: 20px;
// }

// Row
.scrool-H {
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  background-color: black;
  // height: 200px;

  @media (max-width: 576px) {
    flex-direction: column;

    & > a {
      margin-bottom: 20px;
    }
  }
}

.scrool-H::-webkit-scrollbar {
  width: 0;
}

// .liveCard {
//   height: 250px;
//   width: 200px;
//   margin-right: 20px;
//   background-color: #ddd;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// Cards
.item {
  width: 180px;
  height: 230px;
  line-height: 110px;
  text-align: center;
  color: white;
  border-radius: 10px;
  position: relative;
  background-color: white;
}

.item .top-card {
  height: 35px;
  width: 100%;
  background-color: black;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.item .top-card .left {
  display: flex;
  margin-left: 10px;
  font-weight: bold;
  font-size: 0.85em;
}

.item .top-card .right {
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.item .live-img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  object-fit: contain;
  object-position: center center;
}

.item .bottom-card {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: black;
  opacity: 0.45;
}

.item .live-title {
  position: absolute;
  top: 150px;
  z-index: 10;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
}

@media (min-width: 767px) {
  .home-container {
    // width: 75%;
    // max-height: auto;
  }

  // .item-top {
  //   width: 75%;
  //   // margin: auto;
  // }
}
</style>
