import helper from "@/utils/helper";

export default {
  getLowestPrice(product, selectedVariant) {
    if (product.variants.length === 0) {
      return helper.formatPrice(product.price);
    }

    if (selectedVariant) {
      const variant = product.variants.find(variant => variant.id === selectedVariant);
      if (variant.price && variant.price.amount > 0) {
        return helper.formatPrice(variant.price);
      }
    }

    const prices = [...new Set(product.variants.map(variant => variant.price))];
    if (prices.length === 1) {
      const price = prices[0];
      if (price && price.amount > 0) {
        return helper.formatPrice(price);
      }
    } else if (prices.length > 0) {
      const price = prices.reduce((lowestPrice, price) =>
        !lowestPrice || (price && price.amount < lowestPrice.amount) ? price : lowestPrice
      );

      if (price && price.amount > 0) {
        return helper.formatPrice(price);
      }
    }

    return "";
  },
};
