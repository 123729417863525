<template>
  <div class="h-w-100" id="stream-container" :class="{ presenter: isSellerAdmin && isShopSeller }">
    <div class="text-center my-2" v-if="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div class="text-center mt-5" v-else-if="liveNotFound">
      <h3 class="mt-5">
        <b-icon-exclamation-triangle font-scale="5"></b-icon-exclamation-triangle>
        <br />
        {{ $t("stream.not-found") }}
      </h3>
    </div>
    <b-container class="h-100" fluid v-else>
      <b-row class="h-100">
        <b-col xl="4" class="h-100 p-0 d-none d-xl-block" v-if="minWidth1200">
          <div id="products-column">
            <div id="products">
              <b-row cols-md="3" cols-lg="4" cols-xl="3" ref="productsList" id="products-list">
                <!-- Start Card Product -->
                <b-col
                  v-for="liveProduct in products"
                  :id="`product-${liveProduct.id}`"
                  :key="
                    `${liveProduct.id}-${
                      liveProduct.product && (isShopSeller || isFlixby || !live.configuration.showProductStockDisabled)
                        ? liveProduct.product.variants.length > 0
                          ? liveProduct.product.variants.reduce((stock, variant) => stock + variant.stock, 0)
                          : liveProduct.product.stock
                        : 0
                    }`
                  "
                  class="product"
                  :class="{ selected: liveProduct.id === currentProduct }"
                  @click="selectProduct(liveProduct.id)"
                >
                  <product-card
                    :key="liveProduct.id"
                    :live-product="liveProduct"
                    :is-shop-seller="isShopSeller"
                    :show-product-stock-disabled="live.configuration.showProductStockDisabled"
                    :not-shown="isShopSeller && !productShown(liveProduct)"
                    v-on:product-added-to-cart-btn="setProductAddedToCart"
                  ></product-card>
                </b-col>
                <!-- End Card Product -->
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col md="6" xl="4" class="h-100 p-0">
          <div id="live-infos" v-if="!showReplayPlayer">
            <div v-if="(live.startTime || liveStarted) && !live.endTime">
              <b-badge pill class="mr-1 bg-orange" id="live-label">LIVE</b-badge>
              <!--<b-badge pill class="mr-1" id="shop-name">{{ live.shop.name }}</b-badge>-->
              <b-badge pill id="viewers-count">
                <b-icon-eye-fill class="mr-1"></b-icon-eye-fill> {{ viewersCount }}
              </b-badge>
            </div>
          </div>

          <div id="share-infos" v-if="!showReplayPlayer">
            <div v-if="live.startTime && live.sharedCount > 0">
              <b-badge pill id="shared-count" class="bg-shop-color">
                {{ live.sharedCount }}
              </b-badge>
            </div>
          </div>

          <div class="text-center" :class="{ 'big-mute': !muteClicked }" id="mute-btn" v-if="remoteAudioTrack !== null">
            <b-button pill variant="dark" @click="toggleMute">
              <b-icon-volume-mute-fill v-if="isMute"></b-icon-volume-mute-fill>
              <b-icon-volume-up-fill v-else></b-icon-volume-up-fill>
            </b-button>
          </div>

          <div class="text-center" id="stop-countdown" v-if="showStoppedCountdown">
            <countdown :left-time="5000">
              <template v-slot:process="{ timeObj }">
                <span>{{ timeObj.s }}</span>
              </template>
            </countdown>
          </div>

          <div id="share-container">
            <div
              v-if="!isShopSeller"
              class="share-banner"
              :class="{ 'banner-shown': showShareBanner, 'banner-hidden': !showShareBanner }"
            >
              {{ $t("share.banner") }}
            </div>
            <button id="share-button" @click="webShareApiSupported ? webShareApiShare() : toggleShareButtons()">
              <b-icon-share-fill></b-icon-share-fill>
            </button>
            <fade-transition>
              <a
                v-if="showShareButtons"
                target="_blank"
                class="share-button share-button-facebook"
                :href="`https://facebook.com/sharer/sharer.php?u=${encodedShareUrl}`"
                @click="() => shared()"
              >
                <b-icon-facebook scale="1.8"></b-icon-facebook>
              </a>
            </fade-transition>
            <fade-transition>
              <a
                v-if="showShareButtons"
                class="share-button share-button-whatsapp"
                href="javascript:void(0);"
                @click="copyLinkToClipboard"
              >
                <b-icon-link45deg scale="1.8"></b-icon-link45deg>
              </a>
            </fade-transition>
            <fade-transition>
              <a
                v-if="showShareButtons"
                target="_blank"
                class="share-button share-button-mail"
                :href="`mailto:?subject=${encodedShareTitle}&body=${encodedShareText}`"
                @click="() => shared()"
              >
                <b-icon-envelope-fill scale="1.5"></b-icon-envelope-fill>
              </a>
            </fade-transition>
          </div>

          <div ref="player" id="player" class="h-100 w-100 text-center bg position-relative">
            <div id="device-switcher" v-if="isSellerAdmin && isShopSeller && !live.endTime && !showReplayPlayer">
              <template v-if="availableAudioDevices.length > 1">
                <b-button id="select-audio-device-btn" href="javascript:void(0)" tabindex="0" pill>
                  <b-icon-mic></b-icon-mic>
                </b-button>
                <b-popover target="select-audio-device-btn" title="" placement="top" triggers="focus">
                  <b-list-group>
                    <b-list-group-item
                      v-for="device in availableAudioDevices"
                      :key="device.deviceId"
                      href="javascript:void(0);"
                      @click="selectAudioDevice(device)"
                    >
                      {{ device.label }}
                      <b-icon-check2
                        v-if="selectedAudioDevice && selectedAudioDevice.deviceId === device.deviceId"
                      ></b-icon-check2>
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
              </template>

              <template v-if="availableVideoDevices.length > 1">
                <b-button id="select-video-device-btn" href="javascript:void(0)" tabindex="0" pill>
                  <b-icon-camera-video></b-icon-camera-video>
                </b-button>
                <b-popover target="select-video-device-btn" title="" placement="top" triggers="focus">
                  <b-list-group>
                    <b-list-group-item
                      v-for="device in availableVideoDevices"
                      :key="device.deviceId"
                      href="javascript:void(0);"
                      @click="selectVideoDevice(device)"
                    >
                      {{ device.label }}
                      <b-icon-check2
                        v-if="selectedVideoDevice && selectedVideoDevice.deviceId === device.deviceId"
                      ></b-icon-check2>
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
              </template>
            </div>

            <div v-if="showTuto" id="video-tuto">
              <img class="h-w-100" :src="`${apiUrl}/uploads/flixby/waiting.gif`" />
            </div>

            <video
              v-if="showReplayPlayer"
              class="h-w-100"
              ref="replayPlayer"
              id="replay-player"
              controls
              crossorigin
              playsinline
            ></video>

            <div
              id="waiting-message"
              v-if="(!isSellerAdmin || !isShopSeller || live.endTime) && isWaiting && !showReplayPlayer"
            >
              <countdown :end-time="countdownEndTime">
                <template v-slot:process="{ timeObj }">
                  <b-img
                    v-if="live.logoUrl || live.shop.logoUrl"
                    center
                    id="waiting-logo"
                    :src="`${apiUrl}/${live.shop.logoUrl}`"
                    alt="Live logo"
                  />
                  <span class="shop-name shop-color">{{ live ? live.shop.name : "" }}</span
                  ><br />
                  <span class="text-uppercase">{{ $t("stream.starts-in") }}</span>
                  <br />
                  <span id="countdown">{{
                    timeObj.d > 0
                      ? $t("stream.countdown-days", {
                          days: timeObj.d,
                          hours: timeObj.h,
                          minutes: timeObj.m,
                          seconds: timeObj.s,
                        })
                      : $t("stream.countdown", { hours: timeObj.h, minutes: timeObj.m, seconds: timeObj.s })
                  }}</span>
                </template>
                <template v-slot:finish>
                  <template v-if="live.type === 'video'">
                    <p class="text-center" v-if="replayUrl">
                      <b-button id="replay-btn" @click="replay">
                        <b-icon-play class="mr-1"></b-icon-play>
                        {{ $t("stream.play") }}
                      </b-button>
                    </p>
                  </template>
                  <template v-else-if="live.endTime">
                    <p>{{ $t("stream.ended") }}</p>
                    <p class="text-center" v-if="replayUrl">
                      <b-button id="replay-btn" @click="replay">
                        <b-icon-arrow-counterclockwise class="mr-1"></b-icon-arrow-counterclockwise>
                        {{ $t("stream.replay") }}
                      </b-button>
                    </p>
                  </template>
                  <template v-else>
                    <b-spinner type="grow"></b-spinner>
                    <p>{{ $t("stream.waiting-for-host") }}</p>
                  </template>
                </template>
              </countdown>
            </div>

            <fade-transition>
              <div id="product-added-to-cart" v-if="productAddedToCart && !isShopSeller">
                <b-img
                  v-if="productAddedToCart.photoUrl"
                  :src="productAddedToCart.photoUrl"
                  rounded="circle"
                  :alt="productAddedToCart.name"
                ></b-img>
                <p>
                  {{ $t("stream.product-added-to-cart.text-1") }}
                  <span id="product-added-to-cart-name">{{ productAddedToCart.name }}</span>
                  {{ $t("stream.product-added-to-cart.text-2") }}
                </p>
              </div>
            </fade-transition>

            <div id="share-winner" v-if="sharedWinner">
              <lottie-animation
                ref="anim-confetti"
                :auto-play="true"
                :animationData="animConfettiData"
                @complete="() => $refs['anim-confetti'].stop()"
              />
              <fade-transition>
                <p id="share-winner-text">
                  {{ $t("stream.shared-winner.text", { name: sharedWinner.name, amount: live.sharedWinnerAmount }) }}
                </p>
              </fade-transition>
            </div>

            <div class="like">
              <div class="like-floating-hearts">
                <lottie-animation
                  ref="anim-floating-hearts"
                  :auto-play="false"
                  :animationData="animFloatingHeartsData"
                  @complete="() => $refs['anim-floating-hearts'].stop()"
                />
              </div>
            </div>
          </div>

          <div
            id="stream-nav-bottom"
            :class="{ 'replay-running': showReplayPlayer, 'show-like': !isShopSeller && live && !live.endTime }"
          >
            <b-button
              pill
              variant="dark"
              ref="messagesBtn"
              id="messages-btn"
              v-if="maxWidth767"
              @click="toggleMessages()"
              :class="{ active: showMessages }"
            >
              <b-icon-chat-text-fill></b-icon-chat-text-fill>
              <b-badge pill variant="default">{{ filteredMessages.length }}</b-badge>
              <div class="btn-label">{{ $t("stream.nav.messages") }}</div>
            </b-button>

            <template id="stream-controls" v-if="showStreamControls">
              <b-button class="stream-control" variant="primary" v-if="isSellerAdmin && isWaiting" @click="stream">
                <b-spinner small type="grow" v-if="startStreamLoading" class="align-middle mr-2"></b-spinner>
                {{ $t("stream.seller.start") }}
              </b-button>
              <b-button class="stream-control" v-if="!isSellerAdmin && client === null" @click="join">
                {{ $t("stream.seller.join") }}
              </b-button>
              <b-button class="stream-control" v-if="isFlixby" style="background-color: #F27B21;">
                Mode Flixby
              </b-button>
              <b-button class="stream-control bg-shop-color" v-else-if="!isSellerAdmin">
                {{ $t("stream.seller.moderator") }}
              </b-button>
              <b-button class="stream-control" variant="danger" v-if="isSellerAdmin && !isWaiting" @click="stop(true)">
                {{ $t("stream.seller.stop") }}
              </b-button>
            </template>

            <b-button
              pill
              variant="dark"
              ref="productsBtn"
              id="products-btn"
              v-if="maxWidth767"
              @click="toggleProducts()"
              :class="{ active: showProducts }"
            >
              <b-icon-list-ul></b-icon-list-ul>
              <b-badge pill variant="default" v-if="products.length > 0">{{ products.length }}</b-badge>
              <div class="btn-label">{{ $t("stream.nav.products") }}</div>
            </b-button>

            <b-button
              v-if="!isShopSeller && !isFlixby"
              pill
              variant="dark"
              id="cart-btn"
              v-b-toggle="cart && cart.items && cartProducts.length > 0 ? `cart` : false"
            >
              <b-icon-handbag-fill></b-icon-handbag-fill>
              <b-badge pill variant="success">{{ cartProducts.length }}</b-badge>
              <div class="btn-label">{{ $t("stream.nav.cart") }}</div>
            </b-button>

            <a id="like-btn" @click="like" v-if="!isShopSeller && !isFlixby && live && !live.endTime">
              <lottie-animation
                ref="anim-heartbeat"
                :auto-play="false"
                :animationData="animHeartbeatData"
                @complete="() => $refs['anim-heartbeat'].stop()"
              />
            </a>
            <a
              id="like-btn"
              @click="rollTheDice"
              v-else-if="(isShopSeller && userInfo.impersonate && userInfo.adminId) || isFlixby"
            >
              <lottie-animation ref="anim-dice" :auto-play="false" :loop="true" :animationData="animDiceData" />
            </a>
          </div>
        </b-col>
        <b-col
          md="6"
          xl="4"
          id="messages-products-column"
          class="h-100 d-flex flex-column justify-content-end"
          :class="{ 'has-stream-controls': showStreamControls, 'has-replay-controls': showReplayPlayer }"
        >
          <template v-if="!maxWidth767 || showMessages">
            <!--<b-col class="">-->
            <slide-y-down-transition>
              <div class="list-unstyled" ref="messagesList" id="messages-list">
                <div
                  v-for="(message, i) in filteredMessages"
                  :key="i"
                  class="message"
                  :class="{ isSeller: message.seller, isFlixby: message.flixby, isResponse: message.isResponse }"
                >
                  <div class="d-flex flex-row w-100 p-0 m-0" :class="{ 'flex-row-reverse': message.isResponse }">
                    <!-- photo user -->
                    <div
                      class="img flex-shrink-0 d-flex justify-content-center align-items-center"
                      :class="{ 'bg-orange': message.seller }"
                    >
                      <img v-if="message.flixby" src="/img/icons/android-chrome-192x192.png" />
                      <img v-else-if="message.seller && live" :src="`${apiUrl}/${live.shop.logoUrl}`" />
                      <span v-else>{{ message.userName.substr(0, 1) }}</span>
                    </div>
                    <div class="d-flex flex-column message_lan">
                      <!-- message -->
                      <b-icon-arrow-down-right class="response-arrow" v-if="message.isResponse">
                      </b-icon-arrow-down-right>
                      <span class="message-user-name">{{ message.userName }}</span>
                      <div class="message-user flex-wrap">{{ message.text }}</div>
                      <div
                        class="text-right mt-3"
                        v-if="(isShopSeller || isFlixby) && !message.seller && !message.flixby"
                      >
                        <a href="javascript:void(0)" @click="ban(message)" class="text-danger">
                          {{ $t("stream.message.ban") }}
                        </a>
                        <a href="javascript:void(0)" @click="respond(message)" class="ml-3 text-white">
                          {{ $t("stream.message.respond") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide-y-down-transition>
            <!--</b-col>-->

            <div id="send-message-form-row" class="">
              <b-row>
                <b-col>
                  <slide-y-down-transition>
                    <form v-on:submit.prevent="sendMessage" v-if="loggedIn">
                      <div id="respond-to-message" v-if="respondToMessage">
                        {{ $t("stream.message.respond-to") }}: {{ limitChars(respondToMessage.text, 30) }}
                        <b-icon-x-circle-fill @click="cancelRespondToMessage"></b-icon-x-circle-fill>
                      </div>
                      <div class="my-message">
                        <b-form-input v-model="message" class="input-message"></b-form-input>
                        <b-input-group-append>
                          <b-button class="btn-send-message" @click="sendMessage">
                            <b-icon
                              v-if="sendingMessage"
                              height="30px"
                              width="30px"
                              icon="circle-fill"
                              animation="throb"
                              font-scale="4"
                              aria-hidden="true"
                              variant="dark"
                            ></b-icon>
                            <b-icon
                              v-else
                              height="30px"
                              width="30px"
                              icon="arrow-right-square"
                              aria-hidden="true"
                              variant="dark"
                            ></b-icon>
                          </b-button>
                        </b-input-group-append>
                      </div>
                    </form>
                    <b-button
                      v-else-if="!loggedIn"
                      class="bg-orange text-center text-white w-100"
                      variant="dark"
                      v-b-modal.modal-login
                      pill
                    >
                      {{ $t("stream.connect-to-participate") }}
                    </b-button>
                  </slide-y-down-transition>
                </b-col>
              </b-row>
            </div>
          </template>

          <slide-y-down-transition>
            <div
              id="products"
              class="d-block d-xl-none"
              v-if="(!minWidth1200 && !maxWidth767) || (maxWidth767 && showProducts)"
            >
              <div uk-slider="finite: true;" id="products-slider">
                <div class="uk-position-relative uk-visible-toggle" tabindex="-1">
                  <ul class="uk-slider-items uk-grid-small uk-child-width-1-3" uk-grid id="products-list">
                    <li
                      v-for="liveProduct in products"
                      :key="
                        `${liveProduct.id}-${
                          liveProduct.product &&
                          (isShopSeller || isFlixby || !live.configuration.showProductStockDisabled)
                            ? liveProduct.product.variants.length > 0
                              ? liveProduct.product.variants.reduce((stock, variant) => stock + variant.stock, 0)
                              : liveProduct.product.stock
                            : 0
                        }`
                      "
                    >
                      <div
                        class="product"
                        :id="`product-${liveProduct.id}`"
                        @click="selectProduct(liveProduct.id)"
                        :class="{ selected: liveProduct.id === currentProduct }"
                      >
                        <product-card
                          :key="liveProduct.id"
                          :live-product="liveProduct"
                          :is-shop-seller="isShopSeller"
                          :show-product-stock-disabled="live.configuration.showProductStockDisabled"
                          :not-shown="isShopSeller && !productShown(liveProduct)"
                          v-on:product-added-to-cart-btn="setProductAddedToCart"
                        ></product-card>
                      </div>
                    </li>
                  </ul>
                </div>
                <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
              </div>
            </div>
          </slide-y-down-transition>
          <div v-if="isShopSeller || isFlixby" id="stats" class="">
            <b-row class="text-centernavbar">
              <b-col>
                <div class="stats">{{ stats.cart }}</div>
                <div class="stats-title">{{ $t("stats.cart") }}</div>
              </b-col>
              <b-col>
                <div class="stats">{{ stats.orders }}</div>
                <div class="stats-title">{{ $t("stats.orders") }}</div>
              </b-col>
              <b-col>
                <div class="stats">{{ stats.shared }}</div>
                <div class="stats-title">{{ $t("stats.shared") }}</div>
              </b-col>
              <b-col>
                <div class="stats">{{ stats.viewers }}</div>
                <div class="stats-title">{{ $t("stats.viewers") }}</div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <slide-x-right-transition>
        <div
          v-if="
            showCurrentProduct &&
              maxWidth767 &&
              !showProducts &&
              !isShopSeller &&
              currentProductObject &&
              !live.endTime &&
              !isWaiting
          "
          id="current-product-overlay"
        >
          <div class="product selected" @click="selectProduct(currentProduct)">
            <product-card
              :live-product="currentProductObject"
              :is-shop-seller="isShopSeller"
              :show-product-stock-disabled="live.configuration.showProductStockDisabled"
            ></product-card>
          </div>
          <div @click="showCurrentProduct = false" id="hide-current-product-overlay">
            {{ $t("stream.current-product-overlay.hide") }}
          </div>
        </div>
      </slide-x-right-transition>

      <div
        id="product-details-backdrop"
        v-if="clickedProductLoading || clickedProduct"
        @click="closeClickedProduct"
      ></div>
      <slide-y-down-transition>
        <div id="product-details" v-if="clickedProductLoading || clickedProduct">
          <b-overlay :show="clickedProductLoading">
            <div id="prod_det_center">
              <b-icon-x-circle-fill
                id="clicked-product-closed"
                @click="closeClickedProduct"
                variant="light"
              ></b-icon-x-circle-fill>
              <!-- product img + product dispo (left)-->
              <div id="left" class="col-4 col-md-4 p-0" v-if="clickedProduct">
                <div class="price-box">
                  <span>{{ getLowestPrice(clickedProduct, clickedProduct.selectedVariant) }}</span>
                </div>
                <span id="dispo" v-if="!live.configuration.showProductStockDisabled || isShopSeller">
                  {{
                    $t("stream.clicked-product.available-stock", {
                      stock:
                        clickedProduct.variants.length > 0
                          ? clickedProduct.variants.reduce(
                              (stock, variant) =>
                                !clickedProduct.selectedVariant || clickedProduct.selectedVariant === variant.id
                                  ? stock + variant.stock
                                  : stock,
                              0
                            )
                          : clickedProduct.stock,
                    })
                  }}
                </span>
                <div class="product-details--picture">
                  <b-img
                    id="prod_det_img"
                    class=""
                    :src="getProductImage(clickedProduct, clickedProduct.selectedVariant)"
                  />
                </div>
              </div>

              <!-- product tailles + ajouter (right)-->
              <div id="right" class="col-8 col-md-8 h-100 d-flex flex-column justify-content-end" v-if="clickedProduct">
                <div class="h-100 overflow-auto">
                  <!-- Nom du produit -->
                  <div class="product-details--name" v-if="clickedProduct">
                    <span>{{ clickedProduct.name }}</span>
                  </div>

                  <!-- Description du produit -->
                  <div class="product-details--description">
                    {{ clickedProduct.description }}
                  </div>

                  <!-- Tailles -->
                  <div id="clicked-product-variants" class="top" v-if="clickedProduct.variants.length > 0">
                    <span class="mt-2">{{ $t("stream.clicked-product.sizes") }}</span>

                    <b-form-group class="box-sizes" v-if="clickedProduct.variants.length > 0">
                      <b-form-select
                        v-model="clickedProduct.selectedVariant"
                        :options="variantsOptions"
                        id="selectedVariant"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <!-- Ajouter -->
                <div class="w-100 flex-grow-1 ">
                  <b-form-invalid-feedback class="text-center" :force-show="noStock && addToCartDisabled">
                    {{ $t("stream.clicked-product.no-stock") }}
                  </b-form-invalid-feedback>
                  <div class="d-flex justify-content-center align-items-end">
                    <b-button
                      v-if="addToCartDisabled"
                      class="bottom rounded uk-flex uk-flex-middle uk-flex-center"
                      @click="notifyMe(clickedProduct)"
                    >
                      <b-icon-envelope id="clicked-product-add-to-cart" class="m-2" variant="light"></b-icon-envelope>
                      <span>{{ $t("stream.clicked-product.notify-me.btn") }}</span>
                    </b-button>
                    <b-button
                      v-else
                      class="bottom rounded uk-flex uk-flex-middle uk-flex-center"
                      @click="addToCart(clickedProduct)"
                    >
                      <b-icon-plus-circle
                        id="clicked-product-add-to-cart"
                        class="m-2"
                        variant="light"
                      ></b-icon-plus-circle>
                      <span>{{ $t("stream.clicked-product.add-to-cart") }}</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
      </slide-y-down-transition>
    </b-container>

    <b-sidebar id="cart" v-if="cart" :title="$t('stream.cart.title')" bg-variant="white" right shadow backdrop>
      <b-overlay :show="cartLoading" rounded="sm">
        <b-row v-for="item in cartProducts" :key="item.id" align-v="center" class="m-0 mb-4">
          <b-col cols="1" class="p-0">
            <b-img
              v-if="
                item.productVariant && item.productVariant.photoUrl
                  ? item.productVariant.photoUrl
                  : item.product.photoUrl
              "
              :src="
                item.productVariant && item.productVariant.photoUrl
                  ? item.productVariant.photoUrl
                  : item.product.photoUrl
              "
            />
          </b-col>
          <b-col cols="4" class="p-0 pl-2">{{ item.product.name }}</b-col>
          <b-col cols="1" class="p-0 text-center">
            {{ item.productVariant ? item.productVariant.attributes.size : "" }}
          </b-col>
          <b-col cols="3" class="p-0 pl-2">
            <b-form-spinbutton
              v-if="cart.status === 'cart'"
              v-model="item.quantity"
              @change="updateItemQuantity(item)"
              min="0"
            ></b-form-spinbutton>
          </b-col>
          <b-col cols="3" class="p-0 text-right">{{ formatPrice(item.totalPrice) }}</b-col>
        </b-row>
        <b-row class="m-0 mb-1" v-if="cart.total && cartShipping">
          <b-col cols="9" class="p-0 pr-2 text-right">
            <b>{{ $t("stream.cart.shipping") }}:</b>
          </b-col>
          <b-col cols="3" class="p-0 text-right">
            <b>{{ formatPrice(cartShipping.totalPrice) }}</b>
          </b-col>
        </b-row>
        <b-row class="m-0 mb-1" v-if="cart.total && cartDiscount && cartDiscount.discountInfos === 'shared-10'">
          <b-col cols="12" class="p-0 text-right">
            <b>{{ $t("stream.cart.discount") }}</b>
          </b-col>
        </b-row>
        <b-row class="m-0 mb-1" v-if="cart.total && cartDiscount && cartDiscount.discountInfos === 'winner-30'">
          <b-col cols="12" class="p-0 text-right">
            <b>{{ formattedCartDiscount }}</b>
          </b-col>
        </b-row>
        <b-row class="m-0 mb-1" v-if="cart.total && cartDiscount && cartDiscount.discountInfos === 'voucher'">
          <b-col cols="12" class="p-0 text-right">
            <b>{{ formattedCartDiscount }}</b>
          </b-col>
        </b-row>
        <b-row class="m-0 mb-4" v-if="cart.total">
          <b-col cols="9" class="p-0 pr-2 text-right">
            <b>{{ $t("stream.cart.total") }}:</b>
          </b-col>
          <b-col cols="3" class="p-0 text-right">
            <span v-if="cart.totalWithDiscount && cart.totalWithDiscount.amount !== cart.total.amount">
              <span class="strike">{{ formatPrice(cart.total) }}</span>
              <br />
              <b>{{ formatPrice(cart.totalWithDiscount) }}</b>
            </span>
            <b v-else>{{ formatPrice(cart.total) }}</b>
          </b-col>
        </b-row>
        <div class="text-left">
          <b-form-group :label="$t('stream.cart.delivery-method.title')" label-class="uk-text-bold">
            <b-form-radio
              v-for="method in deliveryMethods"
              v-model="cart.deliveryMethod"
              :key="`delivery-method-${method}`"
              :name="method"
              :value="method"
              @change="updateCartDeliveryAndPaymentMethods"
            >
              {{ $t(`stream.cart.delivery-method.${method}`) }}
            </b-form-radio>
            <div
              id="available-shops"
              class="pl-3"
              v-if="cart.deliveryMethod === 'shop_pick_up' && availableShops.length > 1"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-icon-search />
                </b-input-group-prepend>

                <b-form-input
                  id="available-shops-search"
                  type="search"
                  size="sm"
                  v-model="searchAvailableShops"
                  :placeholder="$t('stream.available-shop-search')"
                />
              </b-input-group>
              <div id="available-shops-result">
                <template v-if="filteredAvailableShops.length === 0">
                  {{ $t("stream.available-shop-no-result") }}
                </template>
                <b-form-radio
                  v-else
                  v-for="availableShop in filteredAvailableShops"
                  v-model="selectedDeliveryShop"
                  :key="`delivery-shop-${availableShop.id}`"
                  :name="availableShop.city"
                  :value="availableShop.id"
                  @change="updateCartDeliveryAndPaymentMethods"
                >
                  <b>{{ availableShop.city }}</b> <small>{{ availableShop.info }}</small>
                </b-form-radio>
              </div>
            </div>
            <div class="mr-4 ml-4 mt-2" v-if="cart.deliveryMethod !== 'shop_pick_up' && loggedIn">
              <span class="uk-text-bold">{{ $t("stream.cart.delivery-address.title") }}</span>
              <ul class="list-unstyled uk-margin-remove-bottom">
                <li v-if="cart.deliveryAddress">
                  {{ cart.deliveryAddress.address1 }}
                </li>
                <li v-if="cart.deliveryAddress && cart.deliveryAddress.address2">
                  {{ cart.deliveryAddress.address2 }}
                </li>
                <li v-if="cart.deliveryAddress">
                  {{ cart.deliveryAddress.postalCode }}, {{ cart.deliveryAddress.city }}
                </li>
                <li v-if="cart.deliveryAddress">
                  {{ $t(`user.address.form.countries.${cart.deliveryAddress.country}`) }}
                </li>
                <li>
                  <b-button class="mt-2" variant="outline-dark" size="sm" @click="() => (showAddressModal = true)">
                    {{ $t("common.edit") }} <b-icon-pencil-fill class="ml-1" scale=".8"></b-icon-pencil-fill>
                  </b-button>
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>
        <div class="text-left">
          <b-form-group :label="$t('stream.cart.payment-method.title')" label-class="uk-text-bold">
            <b-form-radio
              v-for="method in paymentMethods"
              v-model="cart.paymentMethod"
              :key="`payment-method-${method}-${cart.deliveryMethod}`"
              :name="method"
              :value="method"
              @change="updateCartDeliveryAndPaymentMethods"
            >
              {{ $t(`stream.cart.payment-method.${method}`) }}
            </b-form-radio>
          </b-form-group>
        </div>
        <div>
          <b-form-checkbox
            id="has-voucher"
            v-model="cart.hasVoucher"
            name="has_voucher"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("stream.cart.has-voucher") }}
          </b-form-checkbox>
          <b-form-group
            :label="$t('stream.cart.comment')"
            label-class="uk-text-bold uk-margin-small-top"
            label-for="cart-comment"
          >
            <b-form-textarea
              id="cart-comment"
              v-model="cart.comment"
              :placeholder="commentPlaceholder"
              rows="3"
              max-rows="6"
              debounce="500"
              :disabled="cart.status !== 'cart'"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="text-center">
          <b-overlay :show="paypalLoading" rounded="sm">
            <div
              id="paypal-button-container"
              class="mt-3"
              ref="paypalBtn"
              v-show="cart.paymentMethod === 'paypal' && loggedIn"
            ></div>
          </b-overlay>
          <b-button
            id="confirm-cart-btn"
            class="mt-3"
            variant="success"
            @click="confirmCart"
            v-show="cart.paymentMethod !== 'paypal' || !loggedIn"
          >
            <b-icon-check-2></b-icon-check-2>
            {{ cart.status === "cart" ? $t("stream.cart.confirm") : $t("stream.cart.pay") }}
          </b-button>
          <b-button
            v-if="cart.status !== 'cart'"
            id="edit-cart-btn"
            class="ml-2 mt-3"
            variant="secondary"
            @click="unlockCart"
          >
            <b-icon-pencil-square></b-icon-pencil-square>
            {{ $t("stream.cart.edit") }}
          </b-button>

          <div class="uk-margin-top" v-if="loggedIn">
            <div class="uk-alert uk-alert-warning" v-if="!live.startTime && !liveStarted">
              {{ $t("stream.cart.cart-presale") }}
            </div>
            <span v-else-if="!cartValidity">{{ $t("stream.cart.cart-valid-until-end") }}</span>
            <countdown :end-time="cartValidity" v-else>
              <template v-slot:process="{ timeObj }">
                <span>{{
                  $t("stream.cart.cart-valid-until", {
                    countdown:
                      timeObj.h > 0
                        ? $t("stream.countdown", { hours: timeObj.h, minutes: timeObj.m, seconds: timeObj.s })
                        : $t("stream.countdown-minutes", { minutes: timeObj.m, seconds: timeObj.s }),
                  })
                }}</span>
              </template>
              <template v-slot:finish></template>
            </countdown>
          </div>
          <div class="uk-margin-top" v-else>
            <div class="uk-alert uk-alert-warning">
              {{ $t("stream.cart.anonymous") }}
            </div>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-modal
      id="address-modal"
      ref="address-modal"
      v-if="!isShopSeller"
      @ok="handleAddressModalOk"
      v-model="showAddressModal"
      :title="$t('user.address.form.title')"
      :ok-title="$t('common.save-btn')"
      :cancel-title="$t('common.cancel-btn')"
      no-close-on-esc
      no-close-on-backdrop
      centered
    >
      <address-form ref="address-form" v-on:address-submitted="addressSubmitted"></address-form>
    </b-modal>
    <b-modal
      id="card-modal"
      ref="card-modal"
      v-if="!isShopSeller"
      @ok="handleCardModalOk"
      @cancel="handleCardModalCancel"
      @close="handleCardModalCancel"
      :title="$t('user.card.form.title')"
      :ok-title="$t('common.save-btn')"
      :cancel-title="$t('common.cancel-btn')"
      no-close-on-esc
      no-close-on-backdrop
      centered
      :busy="cartLoading"
    >
      <b-overlay :show="cartLoading" rounded="sm">
        <div id="card-holder" ref="card-holder"></div>
        <div id="card-errors" ref="card-errors" role="alert"></div>
      </b-overlay>
    </b-modal>
    <b-modal id="reminder-modal" ref="reminder-modal" size="sm" hide-footer hide-header centered>
      <b-form @submit.prevent="remindMe" :disabled="remindMeLoading">
        <p class="text-center"><b-icon-bell-fill font-scale="3" /></p>
        <p class="text-center">
          {{ $t("reminder.text") }}
        </p>

        <vue-phone-number-input
          v-model="reminder.phone"
          @update="updateReminderPhoneNumber"
          default-country-code="BE"
          :preferred-countries="['BE', 'FR']"
        />
        <p class="text-center mt-3">{{ $t("reminder.and-or") }}</p>
        <b-form-input placeholder="Email" v-model="reminder.email" type="email" class="mb-3" />

        <b-button
          type="submit"
          :disabled="remindMeLoading"
          class="bg-orange text-center text-white w-100"
          variant="dark"
        >
          {{ $t("reminder.button") }}
        </b-button>
      </b-form>

      <b-button class="bg-orange text-center text-white w-100 mt-3" variant="dark" @click="addToCalendar">
        <b-icon-calendar-plus /> {{ $t("reminder.calendar-button") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
import { mapGetters, mapState } from "vuex";
import api from "@/utils/api";
import STREAM from "@/utils/constants/stream";
import VueScrollTo from "vue-scrollto";
import helper from "@/utils/helper";
import productHelper from "@/utils/product-helper";
import { FadeTransition, SlideXRightTransition, SlideYDownTransition } from "vue2-transitions";
import { loadStripe } from "@stripe/stripe-js/pure";
import { loadScript as loadPaypal } from "@paypal/paypal-js";
import AddressForm from "@/components/AddressForm";
import "@/styles/stream.scss";
import Bugsnag from "@bugsnag/js";
import copy from "copy-text-to-clipboard";
import Plyr from "plyr";
import Hls from "hls.js";
import md5 from "crypto-js/md5";
import UIkit from "uikit";
import ProductCard from "@/components/product/ProductCard";
import LottieAnimation from "lottie-web-vue";
import { flatten } from "lottie-colorify";
import animHeartbeat from "@/assets/heart-beat-pop-up.json";
import animFloatingHearts from "@/assets/live-stream-reaction-ffoating-hearts.json";
import animDice from "@/assets/dice-rolling.json";
import animConfetti from "@/assets/confetti.json";
import _clone from "lodash.clonedeep";
import VuePhoneNumberInput from "vue-phone-number-input";
import { atcb_action } from "add-to-calendar-button";
import Fuse from "fuse.js";
import { io } from "socket.io-client";
import messagingApi from "../../utils/messagingApi";

export default {
  name: "Stream",
  metaInfo() {
    const overrideCSS = this.overrideCSS;
    return {
      meta: [
        {
          vmid: "apple-itunes-app",
          name: "apple-itunes-app",
          content: `app-id=1606338835, app-argument=${document.location.href}`,
        },
      ],
      style: overrideCSS,
    };
  },
  data() {
    return {
      overrideCSS: [],
      isLoading: false,
      startStreamLoading: false,
      streamToken: null,
      chatToken: null,
      client: null,
      localAudioTrack: null,
      localVideoTrack: null,
      isWaiting: true,
      socket: null,
      message: null,
      messages: [],
      currentProduct: null,
      currentProductObject: null,
      showCurrentProduct: true,
      viewersCount: 0,
      viewersCountInterval: null,
      clickedProductLoading: false,
      clickedProduct: null,
      cartLoading: false,
      stripe: null,
      paypal: null,
      paypalLoading: false,
      paypalTimeout: null,
      showAddressModal: false,
      clientSecret: null,
      cardChanged: false,
      card: null,
      showShareButtons: false,
      uid: null,
      uidChat: null,
      stats: {},
      statsInterval: null,
      remoteAudioTrack: null,
      isMute: true,
      muteClicked: false,
      showStoppedCountdown: false,
      replayUrl: null,
      showReplayPlayer: false,
      facebookStream: null,
      respondToMessage: null,
      minWidth1200: this.isMinWidth1200(),
      maxWidth767: this.isMaxWidth767(),
      showProducts: false,
      showMessages: false,
      liveNotFound: false,
      plyr: null,
      plyrCurrentTimeInterval: null,
      liveStarted: false,
      availableAudioDevices: [],
      availableVideoDevices: [],
      selectedAudioDevice: null,
      selectedVideoDevice: null,
      // videoMediaStreamTrack: null,
      // audioMediaStreamTrack: null,
      showShareBanner: true,
      showedProducts: [],
      productsSlider: null,
      refreshLiveInterval: null,
      cartValidity: null,
      sendingMessage: false,
      cartComment: null,
      selectedDeliveryShop: null,
      hasVoucher: false,
      showTuto: false,
      isSellerAdmin: false,
      lastLike: null,
      //_clone needed to avoid memory leaks: https://github.com/airbnb/lottie-web/issues/1933 https://github.com/airbnb/lottie-web/issues/1159 https://stackoverflow.com/questions/57810972/lottie-animation-in-react-causes-memory-leak-browser-freeze-at-rerenders
      animHeartbeatData: _clone(animHeartbeat),
      animFloatingHeartsData: _clone(animFloatingHearts),
      animDiceData: _clone(animDice),
      animConfettiData: _clone(animConfetti),
      productAddedToCart: null,
      lastProductAddedToCart: null,
      productAddedToCartTimeout: null,
      afterLiveRefreshTimeout: null,
      sharedWinner: null,
      lastSharedWinner: null,
      sharedWinnerTimeout: null,
      reminder: {
        phone: null,
        phoneFull: null,
        email: null,
        live: null,
      },
      remindMeLoading: false,
      searchAvailableShops: null,
    };
  },
  components: {
    SlideYDownTransition,
    SlideXRightTransition,
    FadeTransition,
    AddressForm,
    // WebCam,
    ProductCard,
    LottieAnimation,
    VuePhoneNumberInput,
  },
  computed: {
    ...mapGetters("authentication", ["loggedIn", "userInfo", "isSeller", "isFlixby"]),
    ...mapGetters("authentication", {
      hasSellerAdminRole: "isSellerAdmin",
    }),
    ...mapState({
      live: state => state.currentLive,
      cart: state => state.currentCart,
      currentShop: state => state.currentShop,
    }),
    expectedStartTime() {
      return this.live ? helper.getDate(this.live.expectedStartTime) : null;
    },
    expectedEndTime() {
      const expectedStartTime = this.expectedStartTime;
      if (!expectedStartTime) {
        return null;
      }

      const expectedEndTime = new Date();
      expectedEndTime.setTime(expectedStartTime.getTime() + 60 * 60 * 1000);
      return expectedEndTime;
    },
    isShopSeller() {
      return this.isSeller && this.currentShop && this.userInfo.shop.id === this.currentShop.id;
    },
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL;
    },
    filteredMessages() {
      return this.messages.filter(message => !message.banned);
    },
    showStreamControls() {
      return (this.isShopSeller || this.isFlixby) && this.live && !this.live.endTime; // && this.live.type === "agora";
    },
    noStock() {
      return (
        this.clickedProduct &&
        ((this.clickedProduct.variants.length === 0 && this.clickedProduct.stock === 0) ||
          (this.clickedProduct.selectedVariant &&
            this.clickedProduct.variants.find(variant => variant.id === this.clickedProduct.selectedVariant).stock ===
              0))
      );
    },
    clickedProductPrice() {
      return this.getLowestPrice(this.clickedProduct);
    },
    variantsOptions() {
      return this.clickedProduct.variants.map(variant => ({
        html:
          variant.attributes.size +
          (this.clickedProductPrice ? "" : `<em class='ml-1'><small>${this.formatProductPrice(variant)}</small></em>`),
        value: variant.id,
      }));
    },
    addToCartDisabled() {
      if (this.clickedProduct.allowPreOrder) {
        return false;
      }

      if (this.clickedProduct.variants.length > 0) {
        return !this.clickedProduct.selectedVariant || this.noStock;
      }

      return this.noStock;
    },
    shareUrl() {
      return helper.buildShareUrl(
        this.live,
        this.$i18n.locale,
        this.loggedIn && !this.isShopSeller ? this.userInfo.shareToken : null
      );
    },
    encodedShareUrl() {
      return encodeURIComponent(this.shareUrl);
    },
    shareTitle() {
      return this.$t("share.title", { shopName: this.live.shop.name });
    },
    commentPlaceholder() {
      return this.$t("stream.cart.comment-placeholder");
    },
    encodedShareTitle() {
      return encodeURIComponent(this.shareTitle);
    },
    shareText() {
      return this.$t("share.description", {
        liveDate: helper.formatDateTime(this.live.expectedStartTime),
        url: this.shareUrl,
      });
    },
    encodedShareText() {
      return encodeURIComponent(this.shareText);
    },
    webShareApiSupported() {
      return typeof navigator.share === "function";
    },
    cartProducts() {
      return this.cart && this.cart.items && this.cart.items.length > 0
        ? this.cart.items.filter(item => item.type === "product")
        : [];
    },
    cartShipping() {
      return this.cart && this.cart.items && this.cart.items.length > 0
        ? this.cart.items.find(item => item.type === "shipping")
        : null;
    },
    cartDiscount() {
      return this.cart && this.cart.items && this.cart.items.length > 0
        ? this.cart.items.find(item => item.type === "discount")
        : null;
    },
    formattedCartDiscount() {
      return this.cartDiscount.discountType === "percentage"
        ? `${this.cartDiscount.discount} %`
        : `-${this.formatPrice({ amount: this.cartDiscount.discount, currency: this.cart.total.currency })}`;
    },
    cartId() {
      return this.cart && this.cart.id ? this.cart.id : null;
    },
    countdownEndTime() {
      return this.live && !this.live.endTime ? helper.getDate(this.live.expectedStartTime).getTime() : null;
    },
    rtmEnabled() {
      return !this.live.endTime || new Date().getTime() - helper.getDate(this.live.endTime).getTime() < 2 * 3600 * 1000;
    },
    availableShops() {
      return this.cart
        ? Object.entries(this.cart.availableShops).map(([id, city]) => {
            const citySplit = city.split(/,(.*)/s);
            return { id, city: citySplit[0], info: citySplit[1] || "" };
          })
        : [];
    },
    searchableAvailableShops() {
      return new Fuse(this.availableShops, {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        ignoreLocation: true,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: ["city", "info"],
      });
    },
    filteredAvailableShops() {
      return this.searchAvailableShops
        ? this.searchableAvailableShops.search(this.searchAvailableShops).map(result => result.item)
        : this.availableShops;
    },
    deliveryMethods() {
      if (!this.cart) {
        return [];
      }

      return this.cart.availableDeliveryMethods;
    },
    paymentMethods() {
      if (!this.cart) {
        return [];
      }

      return this.cart.availablePaymentMethods;
    },
    products() {
      if (!this.live) {
        return [];
      }

      if (this.live.endTime || this.isShopSeller) {
        return this.live.orderedProducts;
      }

      if (!this.liveStarted) {
        return this.live.configuration.showProductBeforeStartDisabled
          ? this.live.orderedProducts.filter(product => product.presaleAllowed)
          : this.live.orderedProducts;
      }

      return this.live.configuration.showProductBeforeSelectedDisabled
        ? this.live.orderedProducts.filter(product => this.showedProducts.includes(product.id))
        : this.live.orderedProducts;
    },
  },
  created() {
    this.isLoading = true;
    // this.isSellerAdmin = this.hasSellerAdminRole && process.env.NODE_ENV !== "production";

    AgoraRTC.setLogLevel(process.env.NODE_ENV !== "production" ? 0 : 4);

    window.addEventListener("resize", this.setMinWidth1200);
    window.addEventListener("resize", this.setMaxWidth767);

    this.setMinWidth1200();
    this.setMaxWidth767();

    if (this.maxWidth767) {
      this.showProducts = true;
    }

    const liveId = this.$route.params.id;
    const live = this.refreshLive(liveId, true).then(() => {
      if (!this.isSeller && !this.isFlixby) {
        this.refreshCart(liveId);
        window.fbq("track", "ViewContent", { content_type: "live", contents: [this.live] });

        if (
          localStorage.getItem(`live-${this.live.id}-reminder`) !== "1" &&
          this.expectedStartTime.getTime() > new Date().getTime() + 60 * 60 * 1000
        ) {
          this.$nextTick(() => this.$bvModal.show("reminder-modal"));
        }
      }
    });

    const streamPromise = this.isSellerAdmin ? api.getHostToken(liveId) : api.getAudienceToken(liveId);
    const streamToken = streamPromise.then(response => {
      this.streamToken = response.data.token;
      this.uid = response.data.uid;
    });
    const chatToken = api.getChatToken(liveId).then(response => {
      this.chatToken = response.data.token;
      this.uidChat = response.data.uid;
    });

    Promise.all([live, streamToken, chatToken])
      .then(() => {
        const message = api.getMessages(this.live.id).then(response => {
          const payload = response.data;
          if (payload.total) {
            const messages = [];
            const responses = [];

            payload.data.forEach(msg => {
              if (
                this.loggedIn &&
                !msg.flixby &&
                msg.userId === this.userInfo.id &&
                msg.userName === this.userInfo.name
              ) {
                msg.userName = this.$t("stream.message.me");
              }

              if (msg.text.indexOf("|||") !== -1) {
                responses.push(msg);
              } else {
                messages.push(msg);
              }
            });

            responses.forEach(response => {
              const [text, hash] = response.text.split("|||");
              const index = messages.findIndex(message => message.h === hash);

              if (index === -1) {
                return;
              }

              response.text = text;
              response.isResponse = true;
              messages.splice(index, 0, response);
            });

            this.messages.unshift(...messages.reverse());

            if (!this.maxWidth767) {
              this.showMessages = true;
            }

            this.$nextTick(() => {
              this.scrollMessagesToEnd();
            });
          }
        });

        const promises = [message];
        if (!this.live.endTime) {
          // && this.live.type === "agora"
          promises.push(this.join());
        }

        if (this.rtmEnabled) {
          promises.push(this.initRtm());
        }

        if (this.isShopSeller || this.isFlixby) {
          this.statsInterval = setInterval(() => this.updateStats(), 3000);
        }

        this.refreshLiveInterval = setInterval(
          () => this.refreshLive(this.live.id),
          this.isShopSeller || this.isFlixby ? 3000 : 10000
        );

        return Promise.all(promises);
      })
      .then(() => this.loadTracks())
      .catch(e => {
        if (e.response && e.response.status === 404) {
          this.liveNotFound = true;
          this.isLoading = false;

          return;
        }

        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      });
  },
  mounted() {
    if (this.$route.query.error) {
      this.$bvModal.msgBoxOk(this.$t("user.paid.error"));
      this.$router.push(this.$route.path);
    } else if (this.$route.query.completed) {
      this.$bvModal.msgBoxOk(this.$t("user.paid.success"));
      this.$router.push(this.$route.path);
    }

    if (!this.isShopSeller) {
      this.showBanner(true);
    }
  },
  methods: {
    updateReminderPhoneNumber(payload) {
      this.reminder.phoneFull = payload.e164;
    },
    addToCalendar() {
      atcb_action({
        name: this.shareTitle,
        description: this.shareUrl,
        startDate: helper.formatDateISO(this.expectedStartTime),
        endDate: helper.formatDateISO(this.expectedEndTime),
        startTime: helper.formatTime(this.expectedStartTime),
        endTime: helper.formatTime(this.expectedEndTime),
        options: ["Apple", "Google", "iCal", "Microsoft365", "Outlook.com", "Yahoo"],
        location: this.shareUrl,
        timeZone: "Europe/Brussels",
        iCalFileName: "Flixby-event",
      });
    },
    async remindMe() {
      this.reminder.live = this.live.id;
      console.log(this.reminder);

      if (!this.reminder.phoneFull && !this.reminder.email) {
        return;
      }

      try {
        this.remindMeLoading = true;
        await api.remindMe(this.reminder);

        this.$bvModal.hide("reminder-modal");
      } catch (e) {
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        console.error(e);
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }

      this.remindMeLoading = false;
    },
    setProductAddedToCart(product) {
      console.log(product);
      if (!this.isShopSeller) {
        return;
      }

      messagingApi.productAddedToCart(this.live.id, product)
    },
    showProductAddedToCart(productAddedToCart) {
      console.log("show product added to cart");

      console.log(productAddedToCart);
      if (!this.live || this.isShopSeller) {
        return;
      }

      if (localStorage.getItem(`live-${this.live.id}-product-${productAddedToCart.id}-added-to-cart`) !== null) {
        this.productAddedToCart = null;
        return;
      }

      if (this.lastProductAddedToCart === productAddedToCart.id) {
        return;
      }

      this.productAddedToCart = productAddedToCart;
      this.lastProductAddedToCart = productAddedToCart.id;
      localStorage.setItem(`live-${this.live.id}-product-${productAddedToCart.id}-added-to-cart`, "1");
      if (this.productAddedToCartTimeout) {
        clearTimeout(this.productAddedToCartTimeout);
      }
      this.productAddedToCartTimeout = setTimeout(() => (this.productAddedToCart = null), 3000);
    },
    showShareWinner(sharedWinner) {
      console.log("show share winner");
      console.log(sharedWinner);
      console.log(this.lastSharedWinner);
      console.log(sharedWinner.time);

      if (this.lastSharedWinner === sharedWinner.time) {
        return;
      }

      this.sharedWinner = sharedWinner;
      this.lastSharedWinner = sharedWinner.time;

      if (this.sharedWinnerTimeout) {
        clearTimeout(this.sharedWinnerTimeout);
      }
      this.sharedWinnerTimeout = setTimeout(() => (this.sharedWinner = null), 5000);
    },
    async rollTheDice(e) {
      e.preventDefault();

      this.$refs["anim-dice"].stop();
      this.$refs["anim-dice"].play();

      try {
        const response = await messagingApi.random(this.live.id);
        if (response.data) {
          if (response.data[0]) {
            const sharedWinner = response.data[0];
            sharedWinner.time = Date.now();
            console.log(sharedWinner);

            this.sendMessageToServerAndRTM(
              this.$t("stream.shared-winner.text", { name: sharedWinner.name, amount: this.live.sharedWinnerAmount })
            );
          } else {
            this.$bvToast.toast(this.$t("stream.shared-winner.error-no-winner"), {
              title: this.$t("common.error.title"),
              variant: "danger",
              solid: true,
            });
          }
        }
      } catch (e) {
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        console.error(e);
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }

      this.$refs["anim-dice"].stop();
    },
    like(e) {
      e.preventDefault();

      this.$refs["anim-heartbeat"].stop();
      this.$refs["anim-heartbeat"].play();

      this.liked();
    },
    showLike() {
      if (!this.$refs["anim-floating-hearts"]) {
        return;
      }

      this.$refs["anim-floating-hearts"].stop();
      this.$refs["anim-floating-hearts"].play();
    },
    showLastLike(timestamp) {
      if (this.lastLike === timestamp) {
        return;
      }

      this.lastLike = timestamp;
      this.showLike();
    },
    showBanner(short = false) {
      setTimeout(
        () => {
          this.showShareBanner = true;

          setTimeout(() => {
            this.showShareBanner = false;

            this.showBanner();
          }, 10 * 1000);
        },
        short ? 0 : 5 * 60 * 1000
      );
    },
    async loadDevices() {
      if (!this.isSellerAdmin || !this.isShopSeller) {
        return;
      }

      // navigator.mediaDevices.enumerateDevices().then(mediaDevices => {
      //   mediaDevices.forEach(mediaDevice => {
      //     if (mediaDevice.deviceId === "default") {
      //       return;
      //     }
      //
      //     switch (mediaDevice.kind) {
      //       case "audioinput":
      //         if (!this.selectedAudioDevice) {
      //           this.selectedAudioDevice = mediaDevice;
      //         }
      //
      //         this.availableAudioDevices.push(mediaDevice);
      //         break;
      //       case "videoinput":
      //         if (!this.selectedVideoDevice) {
      //           this.selectedVideoDevice = mediaDevice;
      //         }
      //
      //         this.availableVideoDevices.push(mediaDevice);
      //         break;
      //     }
      //   });
      //
      //   console.log(this.availableVideoDevices);
      //   console.log(this.availableAudioDevices);
      // });

      try {
        this.availableVideoDevices = await AgoraRTC.getCameras();
        this.selectedVideoDevice = this.availableVideoDevices[0] || null;

        console.log(this.availableVideoDevices);
      } catch (e) {
        this.$bvToast.toast(this.$t("mediaDevice.stream-error.text"), {
          title: this.$t("mediaDevice.stream-error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }

      try {
        this.availableAudioDevices = await AgoraRTC.getMicrophones();
        this.selectedAudioDevice = this.availableAudioDevices[0] || null;

        console.log(this.selectedAudioDevice);
      } catch (e) {
        this.$bvToast.toast(this.$t("mediaDevice.stream-error.text"), {
          title: this.$t("mediaDevice.stream-error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }
    },
    async loadTracks() {
      if (!this.isSellerAdmin || !this.isShopSeller || this.live.endTime) {
        return;
      }

      await this.loadDevices();

      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: this.selectedAudioDevice ? this.selectedAudioDevice.deviceId : null,
      });
      this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: this.selectedVideoDevice ? this.selectedVideoDevice.deviceId : null,
        encoderConfig: "1080p_3",
      });

      this.localVideoTrack.play(this.$refs.player.id, {
        mirror:
          !this.selectedVideoDevice || this.selectedVideoDevice.deviceId === this.availableVideoDevices[0].deviceId,
      });
    },
    stopTracks() {
      if (this.localAudioTrack) {
        // Destroy the local audio and video tracks.
        this.localAudioTrack.stop();
        this.localAudioTrack.close();

        this.localAudioTrack = null;
      }

      if (this.localVideoTrack) {
        this.localVideoTrack.stop();
        this.localVideoTrack.close();

        this.localVideoTrack = null;
      }
    },
    selectAudioDevice(device) {
      this.selectedAudioDevice = device;
      if (device) {
        this.localAudioTrack.setDevice(device.deviceId);
      }
    },
    selectVideoDevice(device) {
      this.selectedVideoDevice = device;
      if (device) {
        this.localVideoTrack.stop();
        this.localVideoTrack.setDevice(device.deviceId);
        this.localVideoTrack.play(this.$refs.player.id, {
          mirror:
            !this.selectedVideoDevice || this.selectedVideoDevice.deviceId === this.availableVideoDevices[0].deviceId,
        });
      }
    },
    isMinWidth1200() {
      return window.matchMedia("(min-width: 1200px)").matches;
    },
    setMinWidth1200() {
      this.minWidth1200 = this.isMinWidth1200();
    },
    isMaxWidth767() {
      return window.matchMedia("(max-width: 767px)").matches;
    },
    setMaxWidth767() {
      this.maxWidth767 = this.isMaxWidth767();

      if (!this.maxWidth767) {
        this.showMessages = true;
      }
    },
    startTuto() {
      this.showTuto = true;
    },
    stopTuto() {
      this.showTuto = false;
    },
    replay() {
      if (!this.replayUrl) {
        return;
      }

      this.showReplayPlayer = true;
      this.$nextTick(() => {
        const video = this.$refs.replayPlayer;

        // For more options see: https://github.com/sampotts/plyr/#options
        // captions.update is required for captions to work with hls.js
        this.plyr = new Plyr(video, {
          autoplay: true,
          controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "fullscreen"],
        });

        const liveStartTime = helper.getDate(this.live.startTime);
        this.plyrCurrentTimeInterval = setInterval(() => {
          const currentTime = this.plyr.currentTime;

          liveProducts: for (let i = 0, iLength = this.products.length; i < iLength; i++) {
            const liveProduct = this.products[i];

            if (!liveProduct.timestamps || liveProduct.timestamps.length === 0) {
              continue;
            }

            for (let j = 0, jLength = liveProduct.timestamps.length; j < jLength; j++) {
              const timestamp = liveProduct.timestamps[j];
              const startTime = (helper.getDate(timestamp.startTime).getTime() - liveStartTime.getTime()) / 1000;
              const endTime = timestamp.endTime
                ? (helper.getDate(timestamp.endTime).getTime() - liveStartTime.getTime()) / 1000
                : startTime;

              if (startTime <= currentTime && endTime >= currentTime) {
                this.currentProduct = liveProduct.id;
                break liveProducts;
              }
            }
          }
        }, 500);

        if (!Hls.isSupported() || this.live.type === "video") {
          video.src = this.replayUrl;
        } else {
          // For more Hls.js options, see https://github.com/dailymotion/hls.js
          const hls = new Hls();
          hls.loadSource(this.replayUrl);
          hls.attachMedia(video);
        }
      });
    },
    getLowestPrice(product, selectedVariant) {
      return productHelper.getLowestPrice(product, selectedVariant);
    },
    getProductImage(product, selectedVariant = null) {
      if (selectedVariant) {
        const variant = product.variants.find(variant => variant.id === selectedVariant);

        if (variant.photoUrl) {
          return variant.photoUrl;
        }
      }

      return product.photoUrl;
    },
    limitChars(word, limit) {
      return helper.limitChars(word, limit);
    },
    join() {
      return this.start(this.isSellerAdmin && this.isShopSeller ? STREAM.HOST : STREAM.AUDIENCE);
    },
    async stream() {
      if (!this.isSellerAdmin || !this.isShopSeller || this.startStreamLoading) {
        return;
      }

      try {
        this.startStreamLoading = true;
        const response = await api.startLive(this.live.id);
        if (this.live.streamToFacebook && response.data && response.data.facebook) {
          this.facebookStream = response.data.facebook;
        }

        await this.publishStream().then(() => (this.startStreamLoading = false));
      } catch (e) {
        this.startStreamLoading = false;

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    async start(role = STREAM.AUDIENCE) {
      if (role === STREAM.HOST && (!this.isSellerAdmin || !this.isShopSeller)) {
        role = STREAM.AUDIENCE;
      }

      try {
        this.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
        // level 1 == Set latency level to low latency to use Interactive Live Streaming Standard instead of premium
        await this.client.setClientRole(role, role === STREAM.AUDIENCE ? { level: 1 } : undefined);
        await this.client.join(process.env.VUE_APP_AGORA_APP_ID, this.live.channel, this.streamToken, this.uid);

        this.client.on("user-published", async (user, mediaType) => {
          if (user.uid === this.uid) {
            return;
          }

          // Subscribe to a remote user.
          await this.client.subscribe(user, mediaType);
          console.log("subscribe success");

          // If the subscribed track is video.
          if (mediaType === "video") {
            // Get `RemoteVideoTrack` in the `user` object.
            const remoteVideoTrack = user.videoTrack;
            // // Dynamically create a container in the form of a DIV element for playing the remote video track.
            // const playerContainer = document.createElement("div");
            // // Specify the ID of the DIV container. You can use the `uid` of the remote user.
            // playerContainer.id = user.uid.toString();
            // playerContainer.style.width = "640px";
            // playerContainer.style.height = "480px";
            // this.$refs.player.append(playerContainer);
            //
            // // Play the remote video track.
            // // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
            // remoteVideoTrack.play(playerContainer);

            // Or just pass the ID of the DIV container.
            remoteVideoTrack.play(this.$refs.player.id);
            this.stopTuto();
            this.isWaiting = false;
            this.liveStarted = true;

            if (!this.live.startTime && this.cart.items.length > 0) {
              this.$bvModal.msgBoxOk(this.$t("stream.cart.cart-alert-presale"), { centered: true });
            }
          }

          // If the subscribed track is audio.
          if (mediaType === "audio") {
            // Get `RemoteAudioTrack` in the `user` object.
            this.remoteAudioTrack = user.audioTrack;
            // // Play the audio track. No need to pass any DOM element.
            // this.remoteAudioTrack.play();

            this.isMute = true;
            this.remoteAudioTrack.setVolume(0);
          }

          this.$nextTick(() => {
            this.scrollToCurrentProduct(false);
          });
        });

        this.client.on("user-unpublished", () => {
          // const playerContainer = document.getElementById(user.uid);
          // playerContainer && playerContainer.remove();

          this.isWaiting = true;
          this.afterLiveRefreshTimeout = setTimeout(() => this.refreshLive(this.live.id), 500);
        });

        if (role !== STREAM.HOST || !this.isShopSeller) {
          return;
        }

        // // if (this.videoMediaStreamTrack) {
        // //   this.localVideoTrack = AgoraRTC.createCustomVideoTrack({
        // //     mediaStreamTrack: this.videoMediaStreamTrack,
        // //   });
        // // } else {
        // console.log(this.selectedVideoDevice);
        //
        // // Create a video track from the video captured by a camera.
        // this.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        // // }
        //
        // // if (this.audioMediaStreamTrack) {
        // //   this.localVideoTrack = AgoraRTC.createCustomAudioTrack({
        // //     mediaStreamTrack: this.audioMediaStreamTrack,
        // //   });
        // // } else {
        // // Create an audio track from the audio sampled by a microphone.
        // this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // // }
        //
        // // const playerContainer = document.createElement("div");
        // // // Specify the ID of the DIV container. You can use the `uid` of the remote user.
        // // playerContainer.id = this.uid;
        // // playerContainer.style.width = "640px";
        // // playerContainer.style.height = "480px";
        // // this.$refs.player.append(playerContainer);
        //
        // // Play the remote video track.
        // // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        // this.localVideoTrack.play(this.$refs.player.id);
        // this.isWaiting = false;
      } catch (e) {
        console.log(e);
        await this.stop();

        if (e.code === "OPERATION_ABORTED") {
          return;
        }

        if (e.code === "UID_CONFLICT") {
          this.$bvToast.toast(this.$t("agora.errors.remote-login.text"), {
            title: this.$t("agora.errors.remote-login.title"),
            variant: "danger",
            solid: true,
          });

          return;
        }

        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }
    },
    publishStream() {
      if (!this.client) {
        return;
      }

      this.isWaiting = false;
      // Publish the local audio and video tracks to the channel.
      return this.client.publish([this.localAudioTrack, this.localVideoTrack]).then(() => {
        api.startLiveRecording(this.live.id).catch(e => {
          console.log(e);

          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        });

        if (this.currentProduct) {
          this.selectCurrentProduct(this.currentProduct, true);
        }

        if (this.facebookStream) {
          const liveTranscodingConfig = {
            height: 1920,
            width: 1080,
            videoBitrate: 6300,
            videoFramerate: 30,
            // audioSampleRate: 32000,
            // audioBitrate: 48,
            // audioChannels: 1,
            // videoGop: 30,
            // videoCodecProfile: 100,
            // userCount: 1,
            // // userConfigExtraInfo: {},
            // backgroundColor: 0x0000EE,
            watermark: {
              url: "https://flixby.live/img/watermark.png",
              x: 1080 - 600 - 100,
              y: 30,
              width: 600,
              height: 70,
            },
            // backgroundImage: {
            //   url: "https://agoraio-community.github.io/AgoraWebSDK-NG/img/sd_rtn.jpg",
            //   x: 100,
            //   y: 100,
            //   width: 1080,
            //   height: 520,
            // },
            transcodingUsers: [
              {
                // Set the location coordinates according to your ideas
                x: 0,
                y: 0,
                height: 1920,
                width: 1080,
                zOrder: 0,
                alpha: 1.0,
                // The uid below should be consistent with the uid entered in AgoraRTCClient.join
                // uid must be an integer number
                uid: this.uid,
              },
            ],
          };

          this.client
            .setLiveTranscoding(liveTranscodingConfig)
            .then(() => this.client.startLiveStreaming(this.facebookStream.stream_url, true))
            .catch(e => {
              console.log(e);

              if (process.env.NODE_ENV === "production") {
                Bugsnag.leaveBreadcrumb(JSON.stringify(e));
                Bugsnag.notify(e);
              }
            });
        }
      });
    },
    async stop(stopLive = false, delay = true) {
      if (this.isShopSeller && stopLive) {
        if (delay) {
          setTimeout(() => this.stop(stopLive, false), 5000);
          this.showStoppedCountdown = true;

          return;
        }

        this.showStoppedCountdown = false;

        if (stopLive) {
          if (this.facebookStream) {
            this.client
              .stopLiveStreaming(this.facebookStream.stream_url)
              .then(() => {
                console.log("stop live streaming success");
              })
              .catch(e => {
                console.log(e);

                if (process.env.NODE_ENV === "production") {
                  Bugsnag.leaveBreadcrumb(JSON.stringify(e));
                  Bugsnag.notify(e);
                }
              });
            this.facebookStream = null;
          }

          this.stopTracks();

          if (this.currentProduct) {
            api.stopLiveProduct(this.live.id, this.currentProduct, helper.formatDateTimeISO(new Date()));
          }

          await api
            .stopLiveRecording(this.live.id)
            .catch(e => {
              console.log(e);

              if (process.env.NODE_ENV === "production") {
                Bugsnag.leaveBreadcrumb(JSON.stringify(e));
                Bugsnag.notify(e);
              }
            })
            .then(() => api.stopLive(this.live.id))
            .then(() => this.refreshLive(this.live.id));
        }
      }

      if (this.client) {
        // // Traverse all remote users.
        // this.client.remoteUsers.forEach(() => {
        //   // Destroy the dynamically created DIV container.
        //   // const playerContainer = document.getElementById(user.uid);
        //   // playerContainer && playerContainer.remove();
        //
        //   this.isWaiting = true;
        // });

        // Leave the channel.
        await this.client.leave();
        this.client = null;
      }

      this.isWaiting = true;
    },
    initRtm() {
      // new
      if (this.socket) {
        this.socket?.offAny().disconnect();
        this.socket = null;
      }

      this.socket = io(process.env.VUE_APP_MESSAGING_GATEWAY_URL);

      this.socket?.on("connect", () => {
        // join room
        this.socket?.emit("JOIN_ROOM", { liveId: this.live.id });
      });

      this.socket?.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      this.socket?.on("NEW_MESSAGE", msg => {
        if (msg.text.indexOf("|||") !== -1) {
          const [text, hash] = msg.text.split("|||");
          const index = this.messages.findIndex(message => message.h === hash);

          msg.text = text;
          msg.isResponse = true;
          this.messages.splice(index + 1, 0, msg);
        } else {
          this.messages.push(msg);
        }

        this.$nextTick(() => {
          this.scrollMessagesToEnd();
        });
      });

      this.socket?.on("NEW_LIKE", timestamp => {
        this.showLastLike(timestamp);
      });

      this.socket?.on("CURRENT_PRODUCT", ({ productId }) => {
        if (this.currentProduct !== productId) {
          this.currentProduct = productId;

          if (this.maxWidth767) {
            this.showCurrentProduct = false;
            this.$nextTick(() => {
              this.showCurrentProduct = true;
            });
          }
        }
      });

      this.socket?.on("SHOWED_PRODUCTS", products => {
        this.showedProducts = products;
      });

      this.socket?.on("PRODUCT_ADDED_TO_CART", lastProductAddedToCart => {
        this.showProductAddedToCart(lastProductAddedToCart);
      });

      this.socket?.on("SHARED_WINNER", sharedWinner => {
        this.showShareWinner(sharedWinner);
      });

      this.socket?.on("MEMBERS_COUNT", count => {
        this.viewersCount = count;
      });
    },
    async ban(message) {
      if (!this.isShopSeller) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(this.$t("stream.message.confirm-ban"))
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          return api.banUserFromLive(this.live.id, message.userId);
        })
        .then(() => {
          this.$bvModal.msgBoxOk(this.$t("stream.message.ban-confirmation"));

          this.messages.forEach(msg => {
            if (!msg.seller && parseInt(msg.userId) === parseInt(message.userId)) {
              console.log("banned");
              msg.banned = true;
            }
          });

          console.log(this.messages);
        })
        .catch(e => {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        });
    },
    hashMessage(message) {
      return md5(message.userId + message.text).toString();
    },
    respond(message) {
      this.respondToMessage = message;
    },
    cancelRespondToMessage() {
      this.respondToMessage = null;
    },
    sendMessage(event) {
      event.preventDefault();

      if (this.sendingMessage || !this.message || !this.socket) {
        return false;
      }

      if (this.isSeller && !this.isShopSeller) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
        return false;
      }

      let text = this.message;
      if (this.respondToMessage) {
        text += "|||" + this.respondToMessage.h;
      }

      this.sendMessageToServerAndRTM(text);

      return false;
    },
    sendMessageToServerAndRTM(text) {
      this.sendingMessage = true;

      return messagingApi
        .sendMessage(this.live.id, text)
        .then(() => {
          this.message = null;
          this.scrollMessagesToEnd();
        })
        .catch(e => {
          if (e.response && e.response.status === 403) {
            this.$bvModal.msgBoxOk(this.$t("stream.message.user-banned"));
          } else {
            console.log(
              "Send message to channel " + this.live.channel + " failed, please open console see more details."
            );

            this.$bvToast.toast(this.$t("common.error.text"), {
              title: this.$t("common.error.title"),
              variant: "danger",
              solid: true,
            });

            console.log(e);
            if (process.env.NODE_ENV === "production") {
              Bugsnag.leaveBreadcrumb(JSON.stringify(e));
              Bugsnag.notify(e);
            }
          }
        })
        .then(() => (this.sendingMessage = false));
    },
    scrollMessagesToEnd: function() {
      if (!this.showMessages || !this.$refs.messagesList || !this.$refs.messagesList.lastElementChild) {
        return;
      }

      VueScrollTo.scrollTo(this.$refs.messagesList.lastElementChild, 200, {
        container: this.$refs.messagesList,
      });
    },
    async selectProduct(liveProductId) {
      if (this.isShopSeller) {
        this.selectCurrentProduct(liveProductId);
        return;
      }

      const liveProduct = this.products.find(liveProduct => liveProduct.id === liveProductId);
      this.clickedProductLoading = true;
      const response = await api.getLiveProductDetails(this.live.id, liveProduct.product.id);

      if (!response.data) {
        this.$bvToast.toast(this.$t("product.error.text"), {
          title: this.$t("product.error.title"),
          variant: "danger",
          solid: true,
        });
      }

      if (response.data.variants.length > 0) {
        response.data.selectedVariant = response.data.variants[0].id;
      }

      this.clickedProduct = response.data;

      window.fbq("track", "ViewContent", { content_type: "product", contents: [this.clickedProduct] });

      this.clickedProductLoading = false;

      if (this.plyr) {
        if (!liveProduct.timestamps || liveProduct.timestamps.length === 0) {
          return;
        }

        const liveStartTime = helper.getDate(this.live.startTime);
        const timestamp = liveProduct.timestamps[0];
        this.plyr.currentTime = (helper.getDate(timestamp.startTime).getTime() - liveStartTime.getTime()) / 1000;
      }
    },
    selectCurrentProduct(liveProductId, startingLive = false) {
      if (
        !this.socket ||
        this.live.endTime ||
        (!this.live.startTime && !this.liveStarted) ||
        (this.currentProduct === liveProductId && !startingLive)
      ) {
        return;
      }

      if (this.currentProduct && !startingLive) {
        messagingApi.stopProduct(this.live.id, this.currentProduct, helper.formatDateTimeISO(new Date()));
      }

      this.currentProduct = liveProductId;
      this.showedProducts.push(liveProductId);

      messagingApi.startProduct(this.live.id, this.currentProduct, helper.formatDateTimeISO(new Date()));
    },
    productShown(liveProduct) {
      return this.showedProducts.includes(liveProduct.id);
    },
    formatProductPrice(product) {
      return helper.formatPrice(product.price);
    },
    formatPrice(price) {
      if (price.amount === 0) {
        return this.$t("common.free");
      }

      return helper.formatPrice(price);
    },
    closeClickedProduct() {
      this.clickedProduct = null;
    },
    async refreshCart(liveId = null, paid = false) {
      if (!liveId) {
        if (!this.live) {
          return;
        }

        liveId = this.live.id;
      }

      let cartId = !paid ? this.cartId : null;

      if (!cartId && !this.loggedIn) {
        console.log(this.$store.state.carts);
        cartId = this.$store.state.carts[liveId] ? this.$store.state.carts[liveId].id : null;
      }

      console.log("refresh cart");

      this.cartLoading = true;
      return await api
        .getCart(liveId, cartId)
        .then(response => {
          this.$store.commit("setCurrentCart", { liveId, cart: response.data || {} });
          this.cartLoading = false;

          this.selectedDeliveryShop = this.cart && this.cart.deliveryShop ? this.cart.deliveryShop.id : null;

          if (this.cart && this.cart.paymentMethod === "paypal") {
            this.$nextTick(() => {
              this.initPaypal();
            });
          }

          this.cartValidity = null;
          this.$nextTick(() => {
            this.cartValidity =
              this.cart && this.live && this.cart.validity && this.live.endTime
                ? helper.getDate(this.cart.validity).getTime()
                : null;

            console.log(this.cartValidity);
          });
        })
        .catch(e => {
          this.cartLoading = false;
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(e);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(e));
            Bugsnag.notify(e);
          }
        });
    },
    refreshLive(liveId, loadShopPreferences = false) {
      return api
        .getLive(liveId)
        .then(response => this.$store.commit("setCurrentLive", response.data))
        .then(() => {
          this.isLoading = false;

          if (this.live.endTime) {
            // if (this.refreshLiveInterval) {
            //   clearInterval(this.refreshLiveInterval);
            // }

            if (this.cart && this.cart.id && !this.cart.validity && !this.cartLoading) {
              this.refreshCart();
            }

            if (!this.replayUrl) {
              api.getReplay(this.live.id).then(response => {
                if (response.data && response.data.replayUrl) {
                  this.replayUrl = response.data.replayUrl;
                }
              });
            }
          } else if (!this.isShopSeller) {
            if (this.isWaiting) {
              this.startTuto();
            }

            this.$nextTick(() => {
              this.scrollToCurrentProduct(false);
            });
          }

          if (this.live.shop.color && loadShopPreferences) {
            const textColor = helper.getColorForBackground(this.live.shop.color);
            this.overrideCSS.push({
              vmid: "override-css",
              innerHTML: `
                .bg-orange,
                .bg-shop-color,
                .price-box,
                .number-box,
                #messages-list .message.isSeller .message_lan,
                #stream-container #share-container #share-button:hover,
                #stream-container #mute-btn.big-mute .btn,
                #product-details #prod_det_center #right .bottom,
                #stream-container #stream-nav-bottom #products-btn:hover, #stream-container #stream-nav-bottom #products-btn.active, #stream-container #stream-nav-bottom #messages-btn:hover, #stream-container #stream-nav-bottom #messages-btn.active, #stream-container #stream-nav-bottom #cart-btn:hover, #stream-container #stream-nav-bottom #cart-btn.active,
                #stream-container #stream-nav-bottom #products-btn .badge, #stream-container #stream-nav-bottom #messages-btn .badge, #stream-container #stream-nav-bottom #cart-btn .badge {
                  background-color: ${this.live.shop.color} !important;
                  color: ${textColor} !important;
                }
                .share-banner,
                #messages-list .message.isSeller .message_lan .message-user,
                #product-details #prod_det_center #right .bottom #clicked-product-add-to-cart,
                #product-details #prod_det_center #right .bottom span {
                  color: ${textColor} !important;
                }
                .product.selected .product-card {
                  border-color: ${this.live.shop.color} !important;
                }
                .shop-color {
                  color: ${this.live.shop.color} !important;
                }
              `,
            });

            this.animHeartbeatData = flatten(this.live.shop.color, this.animHeartbeatData);
            this.animFloatingHeartsData = flatten(this.live.shop.color, this.animFloatingHeartsData);
          }
        });
    },
    async notifyMe(product) {
      if (!product) {
        return;
      }

      if (!this.loggedIn) {
        this.$bvModal.show("modal-login");

        return;
      }

      const item = {
        product: product.id,
      };

      if (product.selectedVariant) {
        item.productVariant = product.selectedVariant;
      }

      try {
        await api.productNotifyMe(item);

        this.$bvToast.toast(
          this.$t("stream.clicked-product.notify-me.text", { productName: this.clickedProduct.name }),
          {
            variant: "success",
            solid: true,
          }
        );
      } catch (e) {
        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    addToCart(product) {
      if (this.cart && this.cart.status && this.cart.status !== "cart") {
        this.$bvModal.msgBoxOk(this.$t("stream.cart.locked.text"));
        return;
      }

      if (!product) {
        return;
      }

      const item = {
        product: product.id,
        quantity: 1,
      };

      if (product.selectedVariant) {
        item.productVariant = product.selectedVariant;
      }

      window.fbq("track", "AddToCart", { content_type: "product", contents: [item] });

      this.cartLoading = true;
      this.handleCartUpdateResponse(api.addItemToCart(this.live.id, item, this.cartId), product);
    },
    async handleCartUpdateResponse(promise, product = null) {
      try {
        const response = await promise;
        if (response.status === 201) {
          this.$store.commit("setCurrentCart", { liveId: this.live.id, cart: response.data });
        }

        if (this.clickedProduct) {
          this.$bvToast.toast(this.$t("stream.cart.add-confirmation.text", { productName: this.clickedProduct.name }), {
            title: this.$t("stream.cart.add-confirmation.title"),
            variant: "success",
            solid: true,
          });
        }

        this.closeClickedProduct();
        this.refreshCart();
      } catch (e) {
        this.cartLoading = false;
        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        if (e.response && e.response.status === 400) {
          const payload = e.response.data;
          console.log(payload);

          if (payload.error && payload.error.stock !== undefined) {
            this.$bvToast.toast(
              this.$t(
                payload.error.stock === 0
                  ? "stream.cart.no-stock-error.text"
                  : "stream.cart.not-enough-stock-error.text",
                { stock: payload.error.stock, productName: product.name }
              ),
              {
                title: this.$t(
                  payload.error.stock === 0
                    ? "stream.cart.no-stock-error.title"
                    : "stream.cart.not-enough-stock-error.title"
                ),
                variant: "danger",
                solid: true,
              }
            );

            this.refreshCart();
            return;
          }
        }

        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    updateItemQuantity(item) {
      this.cartLoading = true;
      this.handleCartUpdateResponse(api.updateItemQuantity(this.live.id, this.cartId, item), item.product);
    },
    async initStripe() {
      if (this.stripe) {
        return;
      }

      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
    },
    async initPaypal() {
      if (this.$refs.paypalBtn.children.length > 0) {
        //already initialized
        return;
      }

      this.paypalLoading = true;

      if (!this.paypal) {
        try {
          console.log("init paypal");
          this.paypal = await loadPaypal({ "client-id": process.env.VUE_APP_PAYPAL_ID, currency: "EUR" });

          setTimeout(() => this.initPaypal(), 200);
          return;
        } catch (error) {
          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }

          return;
        }
      }

      console.log("init paypal button");
      return this.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "black",
            layout: "horizontal",
            label: "paypal",
            tagline: false,
          },
          // Sets up the transaction when a payment button is clicked
          createOrder: (data, actions) => {
            return this.confirmCart().then(confirmed => {
              if (!confirmed) {
                throw new Error("Cart not confirmed");
              }

              const currency = this.cart.totalWithDiscount.currency;
              return actions.order.create({
                purchase_units: [
                  {
                    description: this.$t("user.paypal.description", {
                      cartId: this.cart.id,
                      shopName: this.live.shop.name,
                    }),
                    amount: {
                      currency_code: currency,
                      value: this.cart.totalWithDiscount.amount / 100,
                      // breakdown: {
                      // item_total: {
                      //   currency_code: currency,
                      //   value: 19,
                      // },
                      // shipping: {
                      //   currency_code: currency,
                      //   value: this.cart.items.find(item => item.type === "shipping"),
                      // },
                      // tax_total: {
                      //   currency_code: "EUR",
                      //   value: 3.99,
                      // },
                      // },
                    },
                  },
                ],
              });
            });
          },

          // Finalize the transaction after payer approval
          onApprove: (data, actions) => {
            return actions.order.capture().then(orderData => {
              // Successful capture! For dev/demo purposes:
              console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));
              // const transaction = orderData.purchase_units[0].payments.captures[0];
              // alert(
              //   "Transaction " +
              //     transaction.status +
              //     ": " +
              //     transaction.id +
              //     "\n\nSee console for all available details"
              // );

              return this.paid(orderData.id);

              // When ready to go live, remove the alert and show a success message within this page. For example:
              // var element = document.getElementById('paypal-button-container');
              // element.innerHTML = '';
              // element.innerHTML = '<h3>Thank you for your payment!</h3>';
              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          },

          // Pass a function to be called when the customer cancels the payment
          onCancel: (data, actions) => {
            console.log(data);
            console.log(actions);
            this.cartLoading = false;
          },
          onError: error => {
            this.cartLoading = false;

            if (error.message === "Cart not confirmed") {
              return;
            }

            this.$bvToast.toast(this.$t("common.error.text"), {
              title: this.$t("common.error.title"),
              variant: "danger",
              solid: true,
            });

            console.log(error);
            if (process.env.NODE_ENV === "production") {
              Bugsnag.leaveBreadcrumb(JSON.stringify(error));
              Bugsnag.notify(error);
            }
          },
        })
        .render("#paypal-button-container")
        .then(() => {
          console.log("paypal button initialized");
          this.paypalLoading = false;
        })
        .catch(error => {
          console.log("paypal button error");
          this.paypalLoading = false;

          this.$bvToast.toast(this.$t("common.error.text"), {
            title: this.$t("common.error.title"),
            variant: "danger",
            solid: true,
          });

          console.log(error);
          if (process.env.NODE_ENV === "production") {
            Bugsnag.leaveBreadcrumb(JSON.stringify(error));
            Bugsnag.notify(error);
          }
        });
    },
    hasUserAddress() {
      return this.cart.deliveryAddress && this.cart.deliveryAddress.id;
    },
    canShowMondialRelay() {
      let countries = ["BE", "FR"];

      if (this.currentShop.id === 24) {
        countries.push("LU", "NL", "ES", "PT");
      }

      return (
        (!this.cart.deliveryAddress || countries.includes(this.cart.deliveryAddress.country)) &&
        this.currentShop &&
        (this.currentShop.id === 13 || this.currentShop.id === 23 || this.currentShop.id === 24)
      );
    },
    async confirmCart() {
      if (!this.cart.paymentMethod) {
        this.$bvModal.msgBoxOk(this.$t("stream.cart.no-payment-method.text"));

        return;
      }

      if (!this.cart.deliveryMethod) {
        this.$bvModal.msgBoxOk(this.$t("stream.cart.no-delivery-method.text"));

        return;
      }

      if (!this.loggedIn) {
        this.$bvModal.show("modal-login");

        return;
      }

      window.fbq("track", "InitiateCheckout");
      this.cartLoading = true;
      await this.initStripe();

      if (!this.hasUserAddress() && this.cart.deliveryMethod !== "shop_pick_up") {
        this.showAddressModal = true;
        this.cartLoading = false;

        return;
      }

      try {
        if (
          !this.clientSecret ||
          ["card", "bancontact", "klarna"].includes(this.cart.paymentMethod) ||
          this.cart.totalWithDiscount.amount === 0
        ) {
          const response = await api.confirmCart(this.live.id, this.cartId);
          if (!response.data || (!response.data.clientSecret && !response.data.confirmed)) {
            const error = new Error();
            error.error = response;
            error.error_code = response.status;

            throw error;
          }

          if (response.data.confirmed) {
            if (this.cart.paymentMethod === "paypal" && this.cart.totalWithDiscount.amount > 0) {
              return response.data.confirmed;
            }

            this.$root.$emit("bv::toggle::collapse", "cart");
            window.fbq("track", "Purchase", {
              value: (this.cart.totalWithDiscount.amount / 100).toFixed(2),
              currency: this.cart.totalWithDiscount.currency,
            });

            return this.refreshCart().then(() => this.$bvModal.msgBoxOk(this.$t("user.paid.success")));
          }

          this.clientSecret = response.data.clientSecret;
          await this.refreshCart();
          this.cartLoading = true;
        }

        if (this.card && this.clientSecret) {
          const response = await this.stripe.confirmCardPayment(this.clientSecret, {
            payment_method: {
              card: this.card,
            },
          });

          await this.handleStripeResponse(response);
        } else {
          const response = await this.stripe.retrievePaymentIntent(this.clientSecret);

          await this.handleStripeResponse(response);
        }

        this.cartLoading = false;
      } catch (e) {
        this.cartLoading = false;

        const data = e.response ? e.response.data : e.data;
        if (data && data.error && data.error.not_enough_stock) {
          data.error.not_enough_stock.forEach(stockError =>
            this.$bvToast.toast(
              this.$t(
                stockError.product.stock === 0
                  ? "stream.cart.no-stock-error.text"
                  : "stream.cart.not-enough-stock-error.text",
                { stock: stockError.product.stock, productName: stockError.product.name }
              ),
              {
                title: this.$t(
                  stockError.product.stock === 0
                    ? "stream.cart.no-stock-error.title"
                    : "stream.cart.not-enough-stock-error.title"
                ),
                variant: "danger",
                solid: true,
              }
            )
          );

          this.refreshCart();
          return;
        }

        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }
    },
    async unlockCart() {
      try {
        if (this.loggedIn) {
          await api.unlockCart(this.live.id, this.cartId);
        }

        this.clientSecret = null;
        this.cardChanged = null;
        this.card = null;

        return this.refreshCart();
      } catch (e) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }
    },
    async paid(paypalTransactionId = null) {
      try {
        await api.paidCart(this.live.id, this.cartId, paypalTransactionId);
        this.$root.$emit("bv::toggle::collapse", "cart");

        return this.refreshCart(null, true).then(() => this.$bvModal.msgBoxOk(this.$t("user.paid.success")));
      } catch (e) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }
    },
    async handleStripeResponse(response) {
      if (response.paymentIntent) {
        console.log(response.paymentIntent);
        const pi = response.paymentIntent.id;

        if (response.paymentIntent.status === "succeeded") {
          // this.$store.commit("setCurrentCart", { liveId: this.live.id, cart: null });
          this.cartLoading = true;
          this.clientSecret = null;
          this.card = null;
          this.cardChanged = null;

          await this.paid();
          this.cartLoading = false;
          return;
        } else if (
          response.paymentIntent.status === "requires_source" ||
          response.paymentIntent.status === "requires_payment_method"
        ) {
          if (this.cart.paymentMethod === "card") {
            const elements = this.stripe.elements();
            this.cardChanged = false;
            this.card = elements.create("card");
            this.card.on("change", event => {
              this.cardChanged = true;
              const displayError = this.$refs["card-errors"];
              if (event.error) {
                displayError.textContent = event.error.message;
              } else {
                displayError.textContent = "";
              }
            });
            this.$bvModal.show("card-modal");
            this.$nextTick(() => {
              this.card.mount("#card-holder");
            });
          } else if (this.cart.paymentMethod === "bancontact") {
            this.cartLoading = true;
            const response = await this.stripe.confirmBancontactPayment(this.clientSecret, {
              payment_method: {
                billing_details: {
                  name: this.userInfo.name,
                  email: this.userInfo.email,
                },
              },
              return_url: `${process.env.VUE_APP_BACKEND_URL}/checkout/${pi}/complete`,
            });

            await this.handleStripeResponse(response);
            this.cartLoading = false;
          } else if (this.cart.paymentMethod === "klarna") {
            this.cartLoading = true;
            const response = await this.stripe.confirmKlarnaPayment(this.clientSecret, {
              payment_method: {
                billing_details: {
                  name: this.userInfo.name,
                  email: this.userInfo.email,
                  address: {
                    country: this.cart.deliveryAddress.country,
                  },
                },
              },
              return_url: `${process.env.VUE_APP_BACKEND_URL}/checkout/${pi}/complete`,
            });

            await this.handleStripeResponse(response);
            this.cartLoading = false;
          } else {
            throw new Error("Unsupported payment method");
          }

          return;
        } else if (
          response.paymentIntent.status === "requires_source_action" ||
          response.paymentIntent.status === "requires_action" ||
          response.paymentIntent.status === "requires_confirmation"
        ) {
          if (this.cart.paymentMethod === "card") {
            this.cartLoading = true;
            const response = await this.stripe.confirmCardPayment(this.clientSecret);

            await this.handleStripeResponse(response);
            this.cartLoading = false;
          } else if (this.cart.paymentMethod === "bancontact") {
            this.cartLoading = true;
            const response = await this.stripe.confirmBancontactPayment(this.clientSecret, {
              payment_method: {
                billing_details: {
                  name: this.userInfo.name,
                  email: this.userInfo.email,
                },
              },
              return_url: `${process.env.VUE_APP_BACKEND_URL}/checkout/${pi}/complete`,
            });

            await this.handleStripeResponse(response);
            this.cartLoading = false;
          } else {
            throw new Error("Unsupported payment method");
          }

          return;
        }
      }

      if (response.error) {
        console.error(response);

        if (process.env.NODE_ENV === "production") {
          const error = new Error();
          error.error = response;
          error.error_code = response.status;

          Bugsnag.leaveBreadcrumb(JSON.stringify(error));
          Bugsnag.notify(error);
        }

        this.card = null;
        this.refreshCart();
        this.$bvModal.msgBoxOk(response.error.message);

        return;
      }

      console.error(response);
      if (process.env.NODE_ENV === "production") {
        const error = new Error();
        error.error = response;
        error.error_code = response.status;

        Bugsnag.leaveBreadcrumb(JSON.stringify(error));
        Bugsnag.notify(error);
      }
    },
    handleAddressModalOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.$refs["address-form"].handleSubmit();
    },
    async handleCardModalOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      if (this.cardChanged && this.$refs["card-errors"].textContent === "") {
        await this.confirmCart();
        this.$bvModal.hide("card-modal");
      }
    },
    handleCardModalCancel() {
      this.card = null;
      this.cardChanged = false;
    },
    addressSubmitted(address) {
      this.showAddressModal = false;

      api.updateCartAddress(this.live.id, this.cartId, address).then(() => this.refreshCart());
    },
    async updateCartDeliveryAndPaymentMethods() {
      this.cartLoading = true;

      try {
        if (this.cart.deliveryMethod !== "shop_pick_up") {
          if (this.cart.paymentMethod === "shop") {
            this.cart.paymentMethod = null;
          }

          this.selectedDeliveryShop = null;
        }

        if (this.cart.paymentMethod === "paypal") {
          this.initPaypal();
        }

        await api.updateCartDeliveryAndPaymentMethods(
          this.live.id,
          this.cartId,
          this.cart.deliveryMethod || null,
          this.cart.paymentMethod || null,
          this.selectedDeliveryShop || null
        );
        await this.unlockCart();
      } catch (e) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }

      this.cartLoading = false;
    },
    async updateCartComment() {
      if (!this.cartId) {
        return;
      }

      this.cartLoading = true;

      try {
        await api.updateCartComment(this.live.id, this.cartId, this.cart.comment, this.cart.hasVoucher);
      } catch (e) {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });

        console.log(e);
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }
      }

      this.cartLoading = false;
    },
    toggleShareButtons() {
      this.showShareButtons = !this.showShareButtons;
      this.showShareBanner = false;
    },
    webShareApiShare() {
      navigator
        .share({
          title: this.shareTitle,
          text: this.shareText,
          url: this.shareUrl,
        })
        .then(() => this.shared())
        .catch(() => {});
    },
    async shared() {
      try {
        await api.shared(this.live.id);
      } catch (e) {
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        console.error(e);
      }
    },
    async liked() {
      try {
        await messagingApi.like(this.live.id);
      } catch (e) {
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        console.error(e);
      }
    },
    async updateStats() {
      if (!this.isShopSeller && !this.isFlixby) {
        return;
      }

      try {
        const response = await api.getShopDashboard(this.live.id);
        this.stats = response.data;
      } catch (e) {
        if (process.env.NODE_ENV === "production") {
          Bugsnag.leaveBreadcrumb(JSON.stringify(e));
          Bugsnag.notify(e);
        }

        console.error(e);
      }
    },
    toggleMute() {
      this.muteClicked = true;
      this.remoteAudioTrack.play();
      this.remoteAudioTrack.setVolume(this.isMute ? 100 : 0);
      this.isMute = !this.isMute;
    },
    copyLinkToClipboard() {
      if (copy(this.shareUrl)) {
        this.$bvToast.toast(this.$t("share.copy.confirmation.text"), {
          title: this.$t("share.copy.confirmation.title"),
          variant: "success",
          solid: true,
        });

        this.shared();
      } else {
        this.$bvToast.toast(this.$t("common.error.text"), {
          title: this.$t("common.error.title"),
          variant: "danger",
          solid: true,
        });
      }
    },
    toggleProducts() {
      this.showMessages = false;
      this.showCurrentProduct = false;
      this.showProducts = !this.showProducts;

      if (this.showProducts) {
        this.$nextTick(() => {
          this.scrollToCurrentProduct(false);
        });
      }
    },
    toggleMessages() {
      this.showProducts = false;
      this.showCurrentProduct = false;
      this.showMessages = !this.showMessages;

      if (this.showMessages) {
        this.$nextTick(() => {
          this.scrollMessagesToEnd();
        });
      }
    },
    scrollToCurrentProduct(animation = true) {
      const index = this.products.findIndex(liveProduct => liveProduct.id === this.currentProduct);
      this.currentProductObject = index !== -1 ? this.products[index] : null;
      this.showCurrentProduct = this.currentProductObject !== null;

      if (!this.showCurrentProduct) {
        return;
      }

      if (this.minWidth1200) {
        VueScrollTo.scrollTo(`#product-${this.currentProduct}`, 200, {
          container: this.$refs.productsList,
          x: false,
          y: true,
          offset: -60,
        });

        return;
      }

      if (this.live && this.live.configuration.showProductBeforeSelectedDisabled) {
        if (this.productsSlider) {
          this.productsSlider.$destroy();
        }

        this.productsSlider = UIkit.slider(document.getElementById("products-slider"), { index });

        return;
      }

      if (animation) {
        UIkit.slider(document.getElementById("products-slider")).show(index);
      } else {
        UIkit.slider(document.getElementById("products-slider"), { index });
      }
    },
  },
  beforeDestroy() {
    if (this.$refs["anim-heartbeat"]) {
      this.$refs["anim-heartbeat"].stop();
    }

    if (this.$refs["anim-floating-hearts"]) {
      this.$refs["anim-floating-hearts"].stop();
    }

    this.stop();
    this.stopTracks();

    window.removeEventListener("resize", this.setMinWidth1200);
    window.removeEventListener("resize", this.setMaxWidth767);

    if (this.viewersCountInterval) {
      clearInterval(this.viewersCountInterval);
    }

    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }

    if (this.plyrCurrentTimeInterval) {
      clearInterval(this.plyrCurrentTimeInterval);
    }

    if (this.refreshLiveInterval) {
      clearInterval(this.refreshLiveInterval);
    }

    if (this.productAddedToCartTimeout) {
      clearTimeout(this.productAddedToCartTimeout);
    }

    if (this.sharedWinnerTimeout) {
      clearTimeout(this.sharedWinnerTimeout);
    }

    if (this.afterLiveRefreshTimeout) {
      clearInterval(this.afterLiveRefreshTimeout);
    }

    this.$store.commit("setCurrentLive", null);
  },
  watch: {
    currentProduct() {
      this.$nextTick(() => {
        this.scrollToCurrentProduct();
      });
    },
    "cart.hasVoucher"(_, oldValue) {
      if (oldValue === undefined) {
        return;
      }

      this.updateCartComment();
    },
    "cart.comment"() {
      this.updateCartComment();
    },
  },
};
</script>
<style lang="scss" scoped>
#countdown {
  font-size: 2em;
}

#player::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, #0000006b 0%, #fff0 30%, #fff0 85%, #0000006b 100%);
}

#stream-container #player {
}
#stream-container .message {
  background-color: unset !important;
}
/*#stream-container #product-details {
  width: auto;
  left: 52%;
  right: 2%;
}*/
/*#stream-container #product-details #prod_det_center #right {
  justify-content: space-between;
}*/

/*#stream-container #product-details #prod_det_img {
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, calc(-50% + 10px));
  height: calc(100% - 20px);
  object-fit: contain;
}*/

#stream-container #messages-column {
  position: relative;
  overflow-y: auto;
  min-height: 0px;
}

@media (max-width: 767px) {
  .navbar.navbar-dark.bg-grey {
    background-image: none !important;
  }

  /*#stream-container #messages-products-column {
    height: calc(100% - 60px) !important;
  }*/

  #stream-container #messages-products-column.has-stream-controls {
    height: calc(100% - 85px) !important;
  }

  #messages-products-column {
    mask-image: linear-gradient(to top, black 0%, transparent 100%);
  }

  /*#stream-container #product-details {
    width: 90%;
    height: 280px;
    font-size: 0.8rem;
    right: 0;
    left: 0;
  }*/
  /* #stream-container #product-details #prod_det_center #right {
    justify-content: space-between;
  }*/

  /* #stream-container #product-details #prod_det_img {
    top: calc(50% - 20px);
    left: 50%;
    transform: translate(-50%, calc(-50% + 10px));
    height: calc(100% - 20px);
    object-fit: contain;
  }*/

  /*.message_lan {
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 0.8rem;
    text-shadow: 1px 1px gray;
  }*/
  /*.message_lan .message-user-name {
    font-size: 0.7rem !important;
  }*/
}

.bg {
  /*background-image: url("http://192.168.2.10/web/bg.jpg");*/
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.position-bg {
  position: absolute;
  top: 0;
}

.message_lan.isSeller {
  background-color: rgba(242, 123, 33, 0.8);
}

.message_lan .message-user {
  color: white;
}

#respond-to-message {
  position: absolute;
  top: -25px;
  left: 25px;
  z-index: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: white;
  font-size: 13px;
  padding: 3px 10px;
  opacity: 0.5;

  .b-icon.bi {
    cursor: pointer;
  }
}
</style>
