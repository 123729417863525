<template>
  <div class="product-card" :class="{ 'not-shown': notShown }">
    <b-overlay :show="loading">
      <template v-if="product">
        <!-- Prix du Product -->
        <div class="product-header">
          <div class="number-box" v-if="isShopSeller">
            <span>{{ liveProduct.order }}</span>
          </div>
          <div class="price-box" v-if="!liveProduct.shopifyProductUrl">
            <span>{{ getLowestPrice(product) }}</span>
          </div>
        </div>
        <!-- IMG PRODUCT -->
        <div class="box-img">
          <b-img :src="product.photoUrl || product.image" class="img-product" />
        </div>

        <!-- TITLE PRODUCT -->
        <div class="product-metas">
          <div class="product-name">
            {{ limitChars(product.name, 30) }}
          </div>
          <div class="product-stock" v-if="!showProductStockDisabled || isShopSeller || isFlixby">
            <ul class="list-unstyled mb-0" v-if="(isShopSeller || isFlixby) && product.variants.length > 0">
              <li v-for="variant in product.variants" :key="variant.id">
                {{ variant.attributes.size }}:
                {{
                  $t("stream.clicked-product.available-stock", {
                    stock: variant.stock,
                  })
                }}
              </li>
            </ul>
            <template v-else>
              {{
                $t("stream.clicked-product.available-stock", {
                  stock:
                    product.variants.length > 0
                      ? product.variants.reduce((stock, variant) => stock + variant.stock, 0)
                      : product.stock,
                })
              }}
            </template>
          </div>
        </div>
      </template>
      <b-button
        v-if="isShopSeller && showProductStockDisabled && !notShown"
        class="product-added-to-cart-btn bg-shop-color"
        @click="e => productAddedToCart(e)"
        :disabled="productAddedToCartDisabled"
        pill
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-rocket b-icon bi"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
          <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
          <circle cx="15" cy="9" r="1" />
        </svg>
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import helper from "@/utils/helper";
import productHelper from "@/utils/product-helper";
import { mapGetters } from "vuex";
import api from "@/utils/api";

export default {
  name: "ProductCard",
  props: {
    liveProduct: Object,
    isShopSeller: Boolean,
    showProductStockDisabled: Boolean,
    notShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: this.liveProduct.product,
      productAddedToCartDisabled: false,
      productAddedToCartDisabledTimeout: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters("authentication", ["isFlixby"]),
  },
  mounted() {
    if (!this.product) {
      this.productAddedToCartDisabled = true;

      this.loading = true;
      api
        .getShopifyProductData(this.liveProduct.shopifyProductUrl)
        .then(response => {
          this.product = response.data;
          this.product.variants = [];
        })
        .catch(e => this.handleError(e))
        .then(() => (this.loading = false));
    } else {
      this.loading = false;
    }
  },
  methods: {
    limitChars(word, limit) {
      return helper.limitChars(word, limit);
    },
    getLowestPrice(product, selectedVariant) {
      return productHelper.getLowestPrice(product, selectedVariant);
    },
    productAddedToCart(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      this.$emit("product-added-to-cart-btn", this.product);

      if (this.productAddedToCartDisabledTimeout) {
        clearTimeout(this.productAddedToCartDisabledTimeout);
      }

      this.productAddedToCartDisabled = true;
      this.productAddedToCartDisabledTimeout = setTimeout(() => (this.productAddedToCartDisabled = false), 30 * 1000);
    },
  },
  beforeDestroy() {
    if (this.productAddedToCartDisabledTimeout) {
      clearTimeout(this.productAddedToCartDisabledTimeout);
    }
  },
};
</script>
